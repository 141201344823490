/* eslint-disable arrow-body-style */
import { config } from '@abyss/web/tools/config';

const apiBase = config('EVENT_SEARCH_APP_URL');

export const ApiUrl = {
  apiGroups: () => `${window.location.origin}/api/graphql?q=Groups`,
  apiUser: () => `${window.location.origin}/api/graphql?q=User`,
  publishNessEvent: () => `${window.location.origin}/api/graphql?q=Publish`,
  eventSearch: `${apiBase}/app/events/search`,
  eventCuration: `${apiBase}/app/events/search-summary`,
  eventDetail: (id: string) => `${apiBase}/app/events/${id}`,
  portals: `${apiBase}/app/portals`,
  eventTypes: `${apiBase}/app/event-types`,
};
