/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { ajax } from '@src/common/ajax';
import { ApiUrl } from '@src/common/ajax/apiUrl';
import { AjaxResponse } from '@src/common/ajax/types';
import { getDefaultPagedContent } from '@src/common/types';
import { EventItem, EventSearchRequest, EventSearchResponse } from '../types';
import { defaultEventItem } from '../defaultEventItem';

export async function fetchEvents(
  props: EventSearchRequest
): Promise<AjaxResponse<EventSearchResponse>> {
  const fallbackResponse: EventSearchResponse = {
    content: [],
    ...getDefaultPagedContent(),
  };

  const body = JSON.stringify(props);

  const { payload, ...rest } = await ajax(
    fallbackResponse,
    ApiUrl.eventSearch,
    { body }
  );
  return {
    payload: sanitize(payload),
    ...rest,
  };
}

function sanitize(resp: EventSearchResponse): EventSearchResponse {
  let sanitized: EventSearchResponse = {
    content: [],
    ...getDefaultPagedContent(),
  };
  sanitized = { ...sanitized, ...resp };
  sanitized.content = sanitized.content ?? [];
  sanitized.content = sanitized.content.filter((elem) => !!elem);
  sanitized.content = sanitized.content.map(sanitizeElement);
  return sanitized;
}

function sanitizeElement(item: EventItem): EventItem {
  return { ...defaultEventItem, ...item };
}
