/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import {ajax, traceId} from '@src/common/ajax';
import { ApiUrl } from '@src/common/ajax/apiUrl';
import { format } from 'date-fns';
import { isClient } from '@src/common/util/isClient';

const nessEvents = require('./nessEvents.json');

const EPOCH = new Date(0);

export function getCurrentTime(date: Date): string {
  return format(date, 'EEEE dd/MMM HH:mm:ss');
}

export interface PublishResult {
  [key: string]: string | number | boolean | null;
}

export interface PublishRequest {
  path: string;
  level: string; //  "INFO",
  message: string;
  tags: string[];
  op: string; // "POST",
  query: string;
  additionalFields: {
    [key: string]: string | number | boolean;
  };
}

export interface PublishResponse {
  data: {
    publish: {
      [key: string]: string | number | boolean | null;
    };
  };
}

export const createDefaultPublishRequest = (
  overrides: Partial<PublishRequest> = {}
): PublishRequest => {
  const defaultRequest: PublishRequest = {
    path: '/unknown/path',
    level: 'INFO',
    message: 'Some event happened on UI',
    tags: [],
    op: 'POST',
    query: 'N/A',
    additionalFields: {},
  };

  return { ...defaultRequest, ...overrides };
};

/** get the payload and modify if required for a specific landing page */
export const extractNessEventPayloadFor = (page: string): PublishRequest => {
  let payload = nessEvents[page];
  const match = page.match(/\/providers\/edit\/(\d+)/);
  if (match) {
    const id = match[1]; // The captured group containing the provider id
    // remove the id for search key
    const key = page.replace(/\/\d+$/, '');
    payload = {
      ...nessEvents[key],
      path: `/providers/edit/${id}`,
      query: `${id}`,
    };
  }
  return payload;
};

/** simple func to get number from string otherwise returns null */
export function toNumber(str: string): number | null {
  // Try to convert the string to a number
  const num = Number(str);
  // Check if the conversion was successful
  /* eslint-disable no-restricted-globals */
  if (!isNaN(num)) {
    return num;
  }
  return null;
}

/**
 * Given we've landed on some app page, we then want the middleware to fire a NESS event
 * We have known pages which we can service - see {nessEvents.json}
 * @param page some app page the user has landed on
 */
export async function publishPageHitToNess(
  page: string
): Promise<PublishResult> {
  const payload = extractNessEventPayloadFor(page);

  if (!payload) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: 404,
      message: 'No path found - failed to send NESS event',
    });
  }
  const overrides: PublishRequest = createDefaultPublishRequest({
    ...extractNessEventPayloadFor(page),
  });
  return publish(overrides);
}

/**
 * Publish a generic event to NESS - allow overriding of variables
 * @param request The request to publish {PublishRequest}
 */
export async function publishNessEvent(
  request: Partial<PublishRequest>
): Promise<PublishResult> {
  return publish(request);
}

async function publish(
  request: Partial<PublishRequest>
): Promise<PublishResult> {
  // if we're client side then we'll try and add some more context
  let tabId: number = -1;
  let currentDate: Date = EPOCH;
  if (isClient()) {
    // associate an id for this tab instance - we'll send it down to NESS
    const currentTime = Date.now();
    currentDate = new Date(currentTime);

    tabId =
      toNumber(sessionStorage.getItem('tabId') ?? `${currentTime}`) ??
      currentTime;
    sessionStorage.setItem('tabId', `${tabId}`);
    sessionStorage.setItem('session started', `${getCurrentTime(currentDate)}`);
  }

  const overrides: PublishRequest = createDefaultPublishRequest({
    ...request,
  });

  const variables = {
    event: {
      path: `${overrides.path}`,
      level: `${overrides.level}`,
      message: `${overrides.message}`,
      tags: [
        ...overrides.tags,
        `TabId (${tabId})`,
        `Session-Started:${getCurrentTime(currentDate)}`,
      ],
      additionalFields: {
        'x-dfeds-ui-trace-id': traceId,
        ...overrides.additionalFields,
      },
    },
  };

  const body = JSON.stringify({
    operationName: 'Publish',
    query: 'mutation Publish($event: JSON!) {\n  publish(event: $event)\n}\n',
    variables: {
      ...variables,
    },
  });

  const defaultResponse: PublishResponse = {
    data: {
      publish: {
        status: 500,
        message: 'Failed to Publish',
      },
    },
  };

  const resp = await ajax(defaultResponse, ApiUrl.publishNessEvent(), {
    body,
  });

  const { status, message } = resp.payload.data.publish;

  return {
    status,
    message,
  };
}
