/* eslint-disable import/no-cycle */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { create } from 'zustand';
import { routeObserver } from '@src/common/util/routeObserver';
import {
  EventSearchFilterPanelStore,
  FilterPanelState,
  IdType,
  SearchProps,
} from '../types';
import { generateMonths } from '../generateMonths';
import { fetchFilters } from '../api/fetchFilters';
import { filterPanelChangeManager } from './filterPanelChangeManager';
import { eventSearchResultsApi } from './eventSearchResults.state';

const defaultSelections: FilterPanelState = {
  month: '',
  portals: [],
  events: [],
  idType: IdType.none,
  value: '',
};

export const useEventSearchFilterPanelStore =
  create<EventSearchFilterPanelStore>(() => ({
    monthOptions: [],
    monthSelected: defaultSelections.month,
    portalOptions: [],
    portalsSelected: defaultSelections.portals,
    eventOptions: [],
    eventTypePortalItems: [],
    eventsSelected: defaultSelections.events,
    typeOptions: [
      { label: 'Account ID', value: IdType.accountId },
      { label: 'IP Address', value: IdType.ipAddress },
    ],
    typeSelected: defaultSelections.idType,
    typeValidationMsg: '',
    value: defaultSelections.value,
    valueValidationMsg: '',
    searchBtnEnabled: false,
    loading: true,
  }));

export const eventSearchFilterPanelApi = api();

function api() {
  let hasContext = false;
  routeObserver.observeEventSearch(init);

  return {
    setMonthValue,
    setPortalValue,
    setEventValue,
    setTypeValue,
    setValue,
    submit,
    submitKbd,
    init,
    getFilterPanelSearchProps,
    setSearchDisabled,
    setSearchRestored,
  };

  async function init() {
    if (hasContext) {
      filterPanelChangeManager.setSubmitBlocked(false);
      commitState();
      return;
    }

    const [filtersResp, monthOptions] = await Promise.all([
      fetchFilters(),
      generateMonths(new Date()),
    ]);

    const {
      portals: portalOptions,
      eventTypeSelectItems: eventOptions,
      eventTypePortalItems,
    } = filtersResp.payload;
    const portalsSelected = portalOptions.map((option) => option.value);
    const eventsSelected = eventOptions.map((option) => option.value);
    useEventSearchFilterPanelStore.setState({
      eventTypePortalItems,
      monthOptions,
      monthSelected: monthOptions[0].value,
      portalOptions,
      portalsSelected,
      eventOptions,
      eventsSelected,
      loading: false,
    });
    hasContext = true;
    filterPanelChangeManager.setSubmitBlocked(false);
    commitState();
  }

  async function submitKbd(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      await submit();
    }
  }

  async function submit() {
    const valid = validate();
    if (!valid) {
      return;
    }
    useEventSearchFilterPanelStore.setState({
      searchBtnEnabled: false,
    });
    commitState();
    await eventSearchResultsApi.search(getFilterPanelSearchProps(0));
  }

  function setSearchDisabled() {
    useEventSearchFilterPanelStore.setState({ searchBtnEnabled: false });
  }

  function setSearchRestored() {
    useEventSearchFilterPanelStore.setState({
      searchBtnEnabled: filterPanelChangeManager.isSubmittable(
        getFilterPanelState()
      ),
    });
  }

  async function setMonthValue(value: string) {
    const searchBtnEnabled = filterPanelChangeManager.isSubmittable({
      ...getFilterPanelState(),
      month: value,
    });
    useEventSearchFilterPanelStore.setState({
      monthSelected: value,
      searchBtnEnabled,
    });
  }

  function setPortalValue(value: string[]) {
    const searchBtnEnabled = filterPanelChangeManager.isSubmittable({
      ...getFilterPanelState(),
      portals: value,
    });

    const eventOptions = useEventSearchFilterPanelStore
      .getState()
      .eventOptions.map((option) => option.value);
    useEventSearchFilterPanelStore.setState({
      portalsSelected: value,
      eventsSelected: eventOptions,
      searchBtnEnabled,
    });
  }

  function setEventValue(value: string[]) {
    const searchBtnEnabled = filterPanelChangeManager.isSubmittable({
      ...getFilterPanelState(),
      events: value,
    });

    useEventSearchFilterPanelStore.setState({
      eventsSelected: value,
      searchBtnEnabled,
    });
  }

  function setTypeValue(value: IdType) {
    const searchBtnEnabled = filterPanelChangeManager.isSubmittable({
      ...getFilterPanelState(),
      idType: value,
    });

    useEventSearchFilterPanelStore.setState({
      typeSelected: value,
      typeValidationMsg: '',
      value: '',
      valueValidationMsg: '',
      searchBtnEnabled,
    });
  }

  function setValue(event: React.ChangeEvent<HTMLInputElement>) {
    const searchBtnEnabled = filterPanelChangeManager.isSubmittable({
      ...getFilterPanelState(),
      value: event.target.value,
    });

    const isAlreadyRed =
      !!useEventSearchFilterPanelStore.getState().valueValidationMsg;

    useEventSearchFilterPanelStore.setState({
      value: event.target.value,
      valueValidationMsg: isAlreadyRed
        ? validateIdValue(event.target.value)
        : '',
      searchBtnEnabled,
    });
  }

  function getFilterPanelState(): FilterPanelState {
    const {
      monthSelected: month,
      portalsSelected: portals,
      eventsSelected: events,
      typeSelected: idType,
      value,
    } = useEventSearchFilterPanelStore.getState();
    return {
      month,
      portals,
      events,
      idType,
      value,
    };
  }

  function commitState() {
    const state = getFilterPanelState();
    filterPanelChangeManager.commitState(state);
    return state;
  }

  function getFilterPanelSearchProps(pageIndex: number): SearchProps {
    return {
      state: getFilterPanelState(),
      eventTypePortalItems:
        useEventSearchFilterPanelStore.getState().eventTypePortalItems,
      pageIndex,
    };
  }

  function validate() {
    const { typeSelected, value } = useEventSearchFilterPanelStore.getState();
    const valueValidationMsg = validateIdValue(value);
    const validValue = !valueValidationMsg;
    const validTypeSelected = [IdType.accountId, IdType.ipAddress].includes(
      typeSelected
    );
    const typeValidationMsg = validTypeSelected ? '' : 'Select a value';
    useEventSearchFilterPanelStore.setState({
      typeValidationMsg,
      valueValidationMsg,
    });
    return validValue && validTypeSelected;
  }

  function validateIdValue(value: string) {
    return value.trim().length >= 3 ? '' : 'Minimum 3 characters required';
  }
}
