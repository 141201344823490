import React from 'react';

export const ResultsNone = () => {
  return (
    <div
      className="resultsNone"
      data-testid="eventSearchResultsNone"
      data-cy="eventSearchResultsNone"
    >
      <div className="labelResultsNone">No matching records</div>
    </div>
  );
};
