/* eslint-disable arrow-body-style */
import React from 'react';
import { useEventSearchResultsStore } from '../mothership/eventSearchResults.state';
import { ResultsStatus } from '../types';
import { ResultsPending } from './resultsComponents/ResultsPending';
import { ResultsSearching } from './resultsComponents/ResultsSearching';
import { ResultsNone } from './resultsComponents/ResultsNone';
import { Results } from './resultsComponents/Results';
import { ResultsError } from './resultsComponents/ResultsError';

export const EventSearchResults = () => {
  const status = useEventSearchResultsStore((state) => state.resultsStatus);

  if (status === ResultsStatus.pendingSearch) {
    return <ResultsPending />;
  }

  if (status === ResultsStatus.searching) {
    return <ResultsSearching />;
  }

  if (status === ResultsStatus.noResults) {
    return <ResultsNone />;
  }

  if (status === ResultsStatus.error) {
    return <ResultsError />;
  }

  return <Results />;
};
