import { EventItemTableColVisibility } from './types';

export function getDefaultEventItemTableColVisibility(): EventItemTableColVisibility {
  return {
    eventType: true,
    eventDateTime: true,
    ipAddress: true,
    clientId: true,
    accountSourceType: true,
    accountId: true,

    externalTraceId: false,
    askId: false,
    dataSource: false,
    internalTraceId: false,
    sourceReportVersion: false,
    ipState: false,
    ipCountry: false,
    ipCountryCode: false,
  };
}
