/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable arrow-body-style */
import { constants } from '@src/routes/provider/common/constants';
import { create } from 'zustand';
import { getFormattedMonth } from '@src/common/util/formatter';
import { generateTicsAndHeights } from '@src/common/charts/generateTicsAndHeights';
import { Bar } from '@src/common/charts/types';
import { eventSequenceManager } from '@src/common/util/eventSequenceManager';
import { fetchEvents } from '../api/fetchEvents';
import {
  getEventCurationRequest,
  getEventSearchRequest,
} from '../getEventSearchRequest';
import { getTableHeaders, getTableRows } from '../tableData';
import {
  EventItem,
  EventItemTableColVisibility,
  EventSearchResultsStore,
  EventSummary,
  NullableEventSummary,
  ResultsStatus,
  SearchProps,
} from '../types';
import { eventSearchFilterPanelApi } from './eventSearchFilterPanel.state';
import { infiniteScrollManager } from './infiniteScrollManager';
import { fetchEventsCuration } from '../api/fetchEventsCuration';
import { fetchEventDetail } from '../api/fetchEventDetail';
import { getRenderableEventItem } from './getRenderableEventItem';
import { getEventTypeMeta } from './eventTypeMap';
import { getDefaultEventItemTableColVisibility } from '../getDefaultEventItemTableColVisibility';

export const CHART_HEIGHT = 300;

const pageSize = constants.DEFAULT_PAGE_SIZE_OPTIONS.slice().reverse()[0];

export const useEventSearchResultsStore = create<EventSearchResultsStore>(
  () => {
    const tableColVisibility = getDefaultEventItemTableColVisibility();
    return {
      resultsStatus: ResultsStatus.pendingSearch,
      tableResultsStatus: ResultsStatus.results,
      loadingMore: false,
      moreToLoad: true,
      tableColVisibility,
      showTableColSelector: false,
      eventItems: [],
      tableHeader: getTableHeaders(tableColVisibility),
      tableRows: [],
      totalRowCount: 0,
      pageIndex: 0,
      eventChartResponse: { counts: [], totalEvents: 0 },
      chartHeading: '',
      chartTicsAndBars: { bars: [], tics: [] },
      chartXAxisLabels: [],
      hoveredEventSummary: null,
      eventDetailStatus: ResultsStatus.pendingSearch,
      eventDetail: null,
      selectedEventSummary: null,
    };
  }
);

export const eventSearchResultsApi = eventSearchResultsApiFn();

function eventSearchResultsApiFn() {
  const raceConditionDetector = eventSequenceManager();

  infiniteScrollManager.onTriggered(loadMoreEvents);
  return {
    search,
    searchEvents,
    searchEventsKbd,
    setInfiniteScrollElement: infiniteScrollManager.setTriggerElement,
    chartTooltip: chartToolTip(),
    revealEventDetail,
    closeEventDetail,
    setTableColVisibility,
    showTableColSelector,
    hideTableColSelector,
    commitColumnSelector,
    toggleAllVisibleTableCols,
  };

  async function searchEventsKbd(
    e: React.KeyboardEvent<HTMLDivElement>,
    eventSummary: NullableEventSummary
  ) {
    if (e.key !== 'Enter') {
      return;
    }
    await searchEvents(eventSummary);
  }

  async function searchEvents(eventSummary: NullableEventSummary) {
    const snapshot = raceConditionDetector.queueEvent();
    const selectedEventSummary = getToggledEventSummary(eventSummary);
    eventSearchFilterPanelApi.setSearchDisabled();
    const pageIndex = 0;
    useEventSearchResultsStore.setState({
      loadingMore: false,
      tableResultsStatus: ResultsStatus.searching,
      pageIndex,
      selectedEventSummary,
    });
    const props =
      eventSearchFilterPanelApi.getFilterPanelSearchProps(pageIndex);
    const request = getEventSearchRequest({
      ...props,
      pageSize,
      isoDateOverride: getIsoDateOverride(),
    });

    const { payload, success } = await fetchEvents(request);

    if (snapshot.isStale()) {
      return;
    }

    const tableRows = getTableRows({
      eventItems: payload.content,
      tableColVisibility:
        useEventSearchResultsStore.getState().tableColVisibility,
    });

    useEventSearchResultsStore.setState({
      eventItems: payload.content,
      tableRows,
      tableResultsStatus: success ? ResultsStatus.results : ResultsStatus.error,
      totalRowCount: payload.totalElements,
      moreToLoad: !payload.last,
    });

    eventSearchFilterPanelApi.setSearchRestored();
  }

  async function loadMoreEvents() {
    const snapshot = raceConditionDetector.queueEvent();
    const nextPageIndex = useEventSearchResultsStore.getState().pageIndex + 1;
    useEventSearchResultsStore.setState({
      loadingMore: true,
      pageIndex: nextPageIndex,
    });
    eventSearchFilterPanelApi.setSearchDisabled();

    const props =
      eventSearchFilterPanelApi.getFilterPanelSearchProps(nextPageIndex);
    const request = getEventSearchRequest({
      ...props,
      pageSize,
      isoDateOverride: getIsoDateOverride(),
    });
    const { payload } = await fetchEvents(request);

    if (snapshot.isStale()) {
      return;
    }

    const additionalRows = getTableRows({
      eventItems: payload.content,
      tableColVisibility:
        useEventSearchResultsStore.getState().tableColVisibility,
    });
    const currentRows = useEventSearchResultsStore.getState().tableRows;
    const currentEventItems = useEventSearchResultsStore.getState().eventItems;

    useEventSearchResultsStore.setState({
      loadingMore: false,
      eventItems: [...currentEventItems, ...payload.content],
      tableRows: [...currentRows, ...additionalRows],
      moreToLoad: !payload.last,
    });

    eventSearchFilterPanelApi.setSearchRestored();
  }

  async function search(props: SearchProps) {
    const snapshot = raceConditionDetector.queueEvent();
    useEventSearchResultsStore.setState({
      resultsStatus: ResultsStatus.searching,
      selectedEventSummary: null,
      hoveredEventSummary: null,
    });

    const request = getEventSearchRequest({
      ...props,
      pageSize,
      isoDateOverride: getIsoDateOverride(),
    });
    const eventCurationRequest = getEventCurationRequest(request);
    const [tableResponse, chartResponse] = await Promise.all([
      fetchEvents(request),
      fetchEventsCuration(eventCurationRequest),
    ]);

    if (snapshot.isStale()) {
      return;
    }

    const { payload, success: tableSuccess } = tableResponse;
    const { success: chartSuccess } = chartResponse;
    const hasData = payload.content.length > 0;

    const success = chartSuccess && tableSuccess;

    let resultsStatus = ResultsStatus.results;
    if (!success) {
      resultsStatus = ResultsStatus.error;
    } else if (!hasData) {
      resultsStatus = ResultsStatus.noResults;
    }

    const curation = chartResponse.payload.counts;
    const chartXAxisLabels = chartResponse.payload.counts.map((c) =>
      getDay(c.date)
    );

    useEventSearchResultsStore.setState({
      resultsStatus,
      eventItems: payload.content,
      moreToLoad: !payload.last,
      tableRows: getTableRows({
        eventItems: payload.content,
        tableColVisibility:
          useEventSearchResultsStore.getState().tableColVisibility,
      }),
      totalRowCount: tableResponse.payload.totalElements,
      pageIndex: 0,
      eventChartResponse: chartResponse.payload,
      chartHeading: getFormattedMonth(new Date(props.state.month)),
      chartTicsAndBars: generateTicsAndHeights({
        chartHeight: CHART_HEIGHT,
        curation,
        maxValueAddPercent: 10,
      }),
      chartXAxisLabels,
    });
  }

  async function revealEventDetail({ externalTraceId, eventType }: EventItem) {
    useEventSearchResultsStore.setState({
      eventDetailStatus: ResultsStatus.searching,
    });
    const { payload, success } = await fetchEventDetail(externalTraceId);
    useEventSearchResultsStore.setState({
      eventDetailStatus: success ? ResultsStatus.results : ResultsStatus.error,
      eventDetail: success
        ? {
            event: payload,
            eventRenderable: getRenderableEventItem(payload),
            meta: getEventTypeMeta(eventType),
          }
        : null,
    });
  }
  function closeEventDetail() {
    useEventSearchResultsStore.setState({
      eventDetailStatus: ResultsStatus.pendingSearch,
      eventDetail: null,
    });
  }

  function setTableColVisibility(
    tableColVisibility: EventItemTableColVisibility
  ) {
    const { eventItems } = useEventSearchResultsStore.getState();
    const tableRows = getTableRows({ eventItems, tableColVisibility });
    const tableHeader = getTableHeaders(tableColVisibility);
    useEventSearchResultsStore.setState({
      tableColVisibility,
      tableRows,
      tableHeader,
    });
  }

  function showTableColSelector() {
    useEventSearchResultsStore.setState({ showTableColSelector: true });
  }

  function hideTableColSelector() {
    useEventSearchResultsStore.setState({ showTableColSelector: false });
  }

  function commitColumnSelector() {
    const { tableColVisibility } = useEventSearchResultsStore.getState();
    selectColVisibility(tableColVisibility);
  }

  function toggleAllVisibleTableCols({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) {
    const { tableColVisibility } = useEventSearchResultsStore.getState();
    tableColVisibility.externalTraceId = checked;
    tableColVisibility.askId = checked;
    tableColVisibility.dataSource = checked;
    tableColVisibility.internalTraceId = checked;
    tableColVisibility.sourceReportVersion = checked;
    tableColVisibility.ipState = checked;
    tableColVisibility.ipCountry = checked;
    tableColVisibility.ipCountryCode = checked;
    selectColVisibility(tableColVisibility);
  }

  function selectColVisibility(
    tableColVisibility: EventItemTableColVisibility
  ) {
    const { eventItems } = useEventSearchResultsStore.getState();
    useEventSearchResultsStore.setState({
      tableColVisibility: { ...tableColVisibility },
      tableRows: getTableRows({ eventItems, tableColVisibility }),
      tableHeader: getTableHeaders(tableColVisibility),
    });
  }

  function chartToolTip() {
    let element: HTMLDivElement;

    return {
      setElement,
      setHoveredEventsSummary,
      clearHoveredEventsSummary,
      onMouseMove,
    };

    function onMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      if (!element) {
        return;
      }
      const tooltipWidth = element.offsetWidth;
      const tooltipHeight = element.offsetHeight;
      const pageWidth = window.innerWidth;
      const pageHeight = window.innerHeight;
      const offset = 10; // Spacing between cursor and tooltip

      let x = event.pageX + offset;
      let y = event.pageY + offset;

      // Prevent overflow on the right
      if (x + tooltipWidth > pageWidth) {
        x = event.pageX - tooltipWidth - offset;
      }
      // Prevent overflow at the bottom
      if (y + tooltipHeight > pageHeight) {
        y = event.pageY - tooltipHeight - offset;
      }

      element.style.left = `${x}px`;
      element.style.top = `${y}px`;
    }

    function setElement(ref: HTMLDivElement) {
      element = ref;
    }

    function setHoveredEventsSummary({ value, payload }: Bar<EventSummary>) {
      const hoveredEventSummary = value === 0 ? null : payload;
      useEventSearchResultsStore.setState({ hoveredEventSummary });
    }
    function clearHoveredEventsSummary() {
      useEventSearchResultsStore.setState({ hoveredEventSummary: null });
    }
  }

  function getToggledEventSummary(
    eventSummary: NullableEventSummary
  ): NullableEventSummary {
    const { selectedEventSummary } = useEventSearchResultsStore.getState();
    // toggle off or select new
    return selectedEventSummary?.dateIso === eventSummary?.dateIso
      ? null
      : eventSummary;
  }

  function getIsoDateOverride(): string | null {
    const { selectedEventSummary } = useEventSearchResultsStore.getState();
    return selectedEventSummary ? selectedEventSummary.dateIso : null;
  }

  function getDay(isoDate: string) {
    const day = isoDate.split('-').reverse()[0];
    return String(Number(day));
  }
}
