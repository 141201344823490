import { styled } from '@abyss/web/tools/styled';

const REPO_REGEX = /[a-zA-Z]\d+\\.\d+/g;

const ColumnHeaderWidth = styled('div', {
  width: '15%',
  display: 'inline-block',
  alignItems: 'center',
  verticalAlign: 'top',
});
const HeaderElement = styled('div', {
  width: '80%',
  display: 'inline-block',
  alignItems: 'center',
  margin: '5px',
});

/**
 * depending on the users permissions or roles they can either create/edit an FFC provder or just view it
 */
const READ_ONLY_STYLE_UPDATE_FFC_PROVIDER = {
  pointerEvents: 'none',
  opacity: 0.55,
  color: 'darkgray',
};
const READ_WRITE_STYLE_UPDATE_FFC_PROVIDER = {
  color: 'red',
};

const APPROVER_DECISION_INDEX = 11;
const PROCEDURE_CODE_KEY_INDEX = 12;

const NON_HDCS_CELL_PROC_CODE_ERROR = 'N/A for non HDCS';
const ENTER_PROC_CODE_MSG = 'Please enter code';

const HD_CODE = 'HD';
const HDCS_CODE = 'HDCS';
const SD_CODE = 'SD';
const TINSD_CODE = 'TINSD';
const FM_CODE = 'FM';

const HARD_DENY = 'Hard Deny';
const HARD_DENY_CODE_SPECIFIC = 'Hard Deny Code Specific';
const SOFT_DENY = 'Soft Deny';
const TIN_SHUT_DOWN = 'TIN shut down';
const FLAG_REMOVED = 'Flag Removed';

// a sample row with some defaults
const INIT_EMPTY_IDENTITY_GRID_DATA = [
  [null, '', '', '', '', '', '', '', '', '', '', 0, '', '', ''],
  [null, '', '', '', '', '', '', '', '', '', '', 0, '', '', ''],
  [null, '', '', '', '', '', '', '', '', '', '', 0, '', '', ''],
  [null, '', '', '', '', '', '', '', '', '', '', 0, '', '', ''],
  // ['NPI', '', '', 'initial data load', '', 'USA', 'Alabama', '', '', HARD_DENY, '', 1, '', '', ''],
];

/** The various Roles allowed */
const ROLE_SEARCH_CMS = 'search-cms';
const ROLE_SEARCH_EPIM = 'search-epim';
const ROLE_SEARCH_FFC = 'search-ffc';
const ROLE_SEARCH_OPTUMRX = 'search-optumrx';

/** The various Provider Sources currently supported */
const SOURCE_OPTUMRX = 'optum-rx';
const SOURCE_EPIM = 'epim-sanctions';
const SOURCE_FFC = 'ffc';
const SOURCE_CMS = 'cms';

/** EIN = Employer Identification Number */
const EIN_ID_TYPE = 'EIN';

/** MPIN = Medical Provider Identification Number  */
const MPIN_ID_TYPE = 'MPIN';

/** NPI = National Provider Identifier */
const NPI_ID_TYPE = 'NPI';

/** TIN = Tax Identification Number */
const TIN_ID_TYPE = 'TIN';

const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 100];

const LOADING = 'Loading';

export const constants = {
  REPO_REGEX,
  APPROVER_DECISION_INDEX,
  PROCEDURE_CODE_KEY_INDEX,
  NON_HDCS_CELL_PROC_CODE_ERROR,
  ENTER_PROC_CODE_MSG,
  HD_CODE,
  HDCS_CODE,
  SD_CODE,
  TINSD_CODE,
  FM_CODE,
  HARD_DENY,
  HARD_DENY_CODE_SPECIFIC,
  SOFT_DENY,
  TIN_SHUT_DOWN,
  FLAG_REMOVED,
  INIT_EMPTY_IDENTITY_GRID_DATA,
  ColumnHeaderWidth,
  HeaderElement,
  READ_ONLY_STYLE_UPDATE_FFC_PROVIDER,
  READ_WRITE_STYLE_UPDATE_FFC_PROVIDER,
  ROLE_SEARCH_CMS,
  ROLE_SEARCH_OPTUMRX,
  ROLE_SEARCH_EPIM,
  ROLE_SEARCH_FFC,
  SOURCE_OPTUMRX,
  SOURCE_EPIM,
  SOURCE_FFC,
  SOURCE_CMS,
  EIN_ID_TYPE,
  MPIN_ID_TYPE,
  NPI_ID_TYPE,
  TIN_ID_TYPE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  LOADING,
};
