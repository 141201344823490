/* eslint-disable no-param-reassign */
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React, { FC } from 'react';

interface Props {
  label: string;
}

export const ColumnSelectionLocked: FC<Props> = ({ label }) => {
  return (
    <div className="eventSearchColLocked">
      <div className="eventSearchColumnSelectorIcon">
        <IconSymbol icon="lock" variant="outlined" color="$gray5" size="$md" />
      </div>
      <span>{label}</span>
    </div>
  );
};
