/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
import React, { FC } from 'react';
import { EventItem } from '../../../types';
import { getEventTypeMeta } from '../../../mothership/eventTypeMap';
import { EventColor } from './EventColor';
import { eventSearchResultsApi } from '../../../mothership/eventSearchResults.state';

interface Props {
  eventItem: EventItem;
}

export const EventCell: FC<Props> = ({ eventItem }) => {
  const { color, label } = getEventTypeMeta(eventItem.eventType);

  return (
    <div className="eventCell" data-testid="eventCell">
      <EventColor color={color} />
      <span>{label}</span>
      <button
        className="eventCellBtn"
        type="button"
        data-cy={`eventCellMoreBtnExternalTraceId${eventItem.externalTraceId}`}
        data-testid={`eventCellMoreBtnExternalTraceId${eventItem.externalTraceId}`}
        aria-haspopup="dialog"
        onClick={() => eventSearchResultsApi.revealEventDetail(eventItem)}
      >
        more...
      </button>
    </div>
  );
};
