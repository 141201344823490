/* eslint-disable @typescript-eslint/naming-convention */
export interface __EventItemDetail {
  externalTraceId: string;
  askId: string;
  dataSource: string;
  eventType: string;
  eventDateTime: string;
  accountSourceType: string;
  accountId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  ssn: string;
  orgName: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  internalTraceId: string;
  ipAddress: string;
  deviceIds: string[];
  urls: string[];
  userBrowser: string;
  userOss: string[];
  targetPortal: string;
  enrlTelNumber: string;
  userTelNumber: string;
  phoneNumber1: string;
  email: string;
  clientId: string;
  sourceReportVersion: string;
  ipState: string;
  ipCountry: string;
  ipCountryCode: string;
  ipIsp: string;
  bankChangeType: string;
  username: string;
  statusCode: string;
  routingNumber: string;
  accountNumber: string;
  marketType: string;
  paymentType: string;
  providerType: string;
  accountStatus: string;
  accountOwnership: string;
  priorEventValue: string;
  pastEventValue: string;
  createdDateTime: string;
}

export const defaultEventItemDetail: __EventItemDetail = {
  externalTraceId: '',
  askId: '',
  dataSource: '',
  eventType: '',
  eventDateTime: '',
  accountSourceType: '',
  accountId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  ssn: '',
  orgName: '',
  streetAddress: '',
  city: '',
  state: '',
  zip: '',
  internalTraceId: '',
  ipAddress: '',
  deviceIds: [],
  urls: [],
  userBrowser: '',
  userOss: [],
  targetPortal: '',
  enrlTelNumber: '',
  userTelNumber: '',
  phoneNumber1: '',
  email: '',
  clientId: '',
  sourceReportVersion: '',
  ipState: '',
  ipCountry: '',
  ipCountryCode: '',
  ipIsp: '',
  bankChangeType: '',
  username: '',
  statusCode: '',
  routingNumber: '',
  accountNumber: '',
  marketType: '',
  paymentType: '',
  providerType: '',
  accountStatus: '',
  accountOwnership: '',
  priorEventValue: '',
  pastEventValue: '',
  createdDateTime: '',
};
