/* eslint-disable arrow-body-style */
import React from 'react';
import { ResultsChart } from './ResultsChart';
import { ResultsTable } from './tableComponents/ResultsTable';

export const Results = () => {
  return (
    <div data-testid="eventSearchResults" data-cy="eventSearchResults">
      <ResultsChart />
      <ResultsTable />
    </div>
  );
};
