/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Divider } from '@abyss/web/ui/Divider';
import { useDataGrid } from '@abyss/web/hooks/useDataGrid';
import { DataGrid } from '@abyss/web/ui/DataGrid';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { DateInput } from '@abyss/web/ui/DateInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { ConfirmationModal } from '@src/common/Modal';
import { useCreateFFCProvider } from '@src/hooks/providers/ffc/useCreate';
import { Card } from '@src/abyss/web/ui/Card';
import { useUpdateProvider } from '@src/hooks/providers/ffc/useUpdate/useUpdateProvider';
import { Breadcrumbs } from '@src/abyss/web/ui/Breadcrumbs';
import { VisuallyHidden } from '@abyss/web/ui/VisuallyHidden';
import { isEmpty } from 'lodash';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { constraints } from '@src/routes/provider/common/constraints';
import { getReasonPhrase } from 'http-status-codes';
import { Popover } from '@abyss/web/ui/Popover';
import { config } from '@abyss/api/tools/config';
import { useHasRole } from '@src/hooks/useHasRole';
import { Main } from '@src/app/Main/Main';
import { constants } from '../../common/constants';
import { utils, IProviderHeading } from '../../common/dataGridUtils';
import { processor } from '../../common/process';
import { publishNessEvent } from '../../../../common/logging/publishNessEvent';

const ColumnElement = constants.ColumnHeaderWidth;
const Header = constants.HeaderElement;

export const FFCProviderCreatePage = ({
  triggerSearch,
  tiggerUpdatedProvider,
  selectOptionsProps,
}) => {
  const { navigate } = useRouter();

  const [, getUsersRoles] = useHasRole({
    onCompleted: (response) => {
      const canCreateFFC = Object.freeze(response?.data?.hasRole);
      if (!canCreateFFC['write-ffc']) navigate('/');
    },
    onError: (_err) => {
      console.error(_err);
      navigate('/');
    },
  });
  useState(() => {
    return getUsersRoles({
      variables: {
        roles: Object.freeze(['write-ffc']),
      },
    });
  });

  // after a create op returns successfully we'll hold onto the newly created object for any subsequent updates
  const [fraudCase, setFraudCase] = useState({ id: null });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleInitial, setMiddleInitial] = useState('');
  const [orgName, setOrgName] = useState('');
  const [tipSource, setTipSource] = useState('');
  const [notes, setNotes] = useState('');
  const [providerTypeCode, setProviderTypeCode] = useState('');
  const [presentedDate, setPresentedDate] = useState('');
  const [requestedDecisionCode, setRequestedDecisionCode] = useState('');
  const [dollarBills, setDollarBills] = useState('');

  const [, setDisallowedEntitiesGridData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [, setUrl] = useState('');
  const [cancelMsg, setCancelMsg] = useState('');
  const [cancelTitle, setCancelTitle] = useState('Cancel');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState('Creating Provider');
  const [loadingMsg, setLoadingMsg] = useState('Setting up provider info...');

  const [minHeadersPopulated, setMinHeadersPopulated] = useState(false);

  // the disallowed entities data grid
  const dataGridPropsColumns = [
    {
      title: 'Id',
      type: 'number',
      minWidth: 120,
      disabled: true,
    },
    {
      title: `Id Type ${config('REQUIRED_FIELD_INDICATOR')}`,
      placeholder: 'ID Type',
      type: 'select',
      options: selectOptionsProps.providerIdentifierTypes,
      // minWidth: 120,
      maxListHeight: 200,
      cellClassName: () => {
        return <span style={{ color: 'red' }}>&#10033; rowData</span>;
      },
    },
    {
      title: `Id Value ${config('REQUIRED_FIELD_INDICATOR')}`,
      type: 'number',
      minWidth: 120,
      cellClassName: ({ rowData }) => {
        if (rowData?.length > 150) {
          return 'custom-red-text';
        }
      },
    },
    {
      title: 'Name',
      placeholder: 'Enter Text',
      defaultValue: '',
      minWidth: 120,
      cellClassName: ({ rowData }) => {
        if (rowData?.length > 150) {
          return 'custom-red-text';
        }
      },
    },
    {
      title: 'Address 1',
      placeholder: 'Enter Text',
      defaultValue: '',
      cellClassName: ({ rowData }) => {
        if (rowData?.length > 150) {
          return 'custom-red-text';
        }
      },
    },
    {
      title: 'Address 2',
      placeholder: 'Enter Text',
      defaultValue: '',
      cellClassName: ({ rowData }) => {
        if (rowData?.length > 150) {
          return 'custom-red-text';
        }
      },
    },
    // { // apparently we'll only use USA until we don't, right :-)
    // 	title: 'Country',
    // 	type: 'select',
    // 	options: MOCK_COUNTRIES_DATA,
    // 	placeholder: 'Pick an option',
    // 	minWidth: 120,
    // },
    {
      title: `State ${config('REQUIRED_FIELD_INDICATOR')}`,
      type: 'select',
      options: selectOptionsProps.states,
      minWidth: 120,
    },
    {
      title: 'City',
      placeholder: 'Enter Text',
      defaultValue: '',
      cellClassName: ({ rowData }) => {
        if (rowData?.length > 150) {
          return 'custom-red-text';
        }
      },
    },
    {
      title: 'Postal Code',
      placeholder: 'add number here',
      defaultValue: '',
      minWidth: 120,
      cellClassName: ({ rowData }) => {
        if (rowData?.length > 150) {
          return 'custom-red-text';
        }
      },
    },
    {
      title: `Appr Decision ${config('REQUIRED_FIELD_INDICATOR')}`,
      type: 'select',
      options: selectOptionsProps.decisionCodes,
      minWidth: 200,
      validator: (p1) => {
        const arr = Object.values(p1);
        const approverDecision = arr[constants.APPROVER_DECISION_INDEX];
        const procedureCodeKey =
          Object.keys(p1)[constants.PROCEDURE_CODE_KEY_INDEX];
        if (approverDecision === constants.HDCS_CODE) {
          return {
            ...p1,
            [procedureCodeKey]: constants.ENTER_PROC_CODE_MSG,
          };
        }
        return {
          ...p1,
          [procedureCodeKey]: constants.NON_HDCS_CELL_PROC_CODE_ERROR,
        };
      },
    },
    {
      title: 'Procedure-Codes',
      defaultValue: '',
      minWidth: 150,
      validator: (p1) => {
        const arr = Object.values(p1);
        const approverDecision = arr[constants.APPROVER_DECISION_INDEX];
        if (approverDecision === constants.HDCS_CODE) {
          // allow entry of codes
          return p1;
        }
        // do not allow entry of codes
        const procedureCodeKey =
          Object.keys(p1)[constants.PROCEDURE_CODE_KEY_INDEX];
        return {
          ...p1,
          [procedureCodeKey]: constants.NON_HDCS_CELL_PROC_CODE_ERROR,
        };
      },
      cellClassName: ({ rowData }) => {
        if (rowData?.length > constraints.procedureCodes.maxLen) {
          return 'custom-red-text';
        }
        if (
          constraints.procedureCodes.exclude.some((value) => {
            return rowData?.includes(value);
          })
        ) {
          return 'custom-red-text';
        }
        if (rowData === constants.NON_HDCS_CELL_PROC_CODE_ERROR) {
          return 'custom-grey-text';
        }
      },
    },
    {
      title: `Appr Decision Date ${config('REQUIRED_FIELD_INDICATOR')}`,
      type: 'date',
      // defaultValue: `${dayjs().format('MM/DD/YYYY')}`,
      minWidth: 180,
    },
    {
      title: `Reason For Approved Decision ${config(
        'REQUIRED_FIELD_INDICATOR'
      )}`,
      type: 'select',
      options: selectOptionsProps.decisionReasons,
      minWidth: 250,
    },
    {
      title: 'Removal Date',
      type: 'date',
      // defaultValue: `${dayjs().format('MM/DD/YYYY')}`,
      minWidth: 120,
    },
    {
      title: 'Removal Reason',
      placeholder: 'Enter Text',
      cellClassName: ({ rowData }) => {
        if (rowData?.length > 150) {
          return 'custom-red-text';
        }
      },
    },
  ];

  const dataGridProps = useDataGrid({
    initialData: constants.INIT_EMPTY_IDENTITY_GRID_DATA,
    initialColumns: dataGridPropsColumns,
    hideGutterColumn: true,
    disableInsertColumn: true,
    rowResize: false,
    columnResize: true,
    columnMove: false,
    rowMove: false,
    columnSort: true,
    columnFilter: false,
    startRows: 4,
    maxHeight: '100%',
    onRowCreate: (columns) => {
      // eslint-disable-next-line array-callback-return
      const defaultData = columns.reduce((dataObj, { id }) => {
        if (id !== 'hidden-gutter')
          return {
            ...dataObj,
            [id]: '',
          };
      }, {});
      return defaultData;
    },
    css: {
      'custom-red-text': {
        '.abyss-text-input, .abyss-data-grid-cell-display-text': {
          color: '$error1',
          fontWeight: '$bold',
        },
      },
      'custom-grey-text': {
        '.abyss-text-input, .abyss-data-grid-cell-display-text': {
          color: '$gray6',
          backgroundColor: '$gray2',
          fontWeight: '$bold',
        },
      },
    },
  });

  const form = useForm({
    defaultValues: {
      requestedDecisionCode: constants.HD_CODE,
      dollarBills: '0.0',
    },
  });

  // Target specific fields by their names
  const WatchFields = form.watch();

  const resetPage = () => {
    setFraudCase({ id: null });
    const resetWith = {
      presentedDate: '',
      requestedDecisionCode: constants.HD_CODE,
      firstName: null,
      lastName: null,
      orgName: null,
      country: null,
      state: null,
      dollarBills: '0.0',
      middleInitial: null,
      providerTypeCode: null,
      tipSource: null,
      notes: null,
    };

    form.reset(resetWith, {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
    dataGridProps.updateData([]);
    setDisallowedEntitiesGridData(dataGridProps.getData());
  };

  const displayError = (error) => {
    setIsLoading(false);

    const title =
      getReasonPhrase(error.status) ||
      error?.data?.title ||
      error?.data?.message ||
      error?.message ||
      'Unexpected Error!';

    const violations = error?.data?.violations
      ?.map((e) => {
        return `${e?.path}:${e?.message}`;
      })
      ?.join('\n');
    const message = violations || error?.data?.detail;

    utils.popupMsg(title, message);
  };

  const [, createProvider] = useCreateFFCProvider({
    onCompleted: (response) => {
      const status = response?.data?.createFFCProvider.status;

      if (status === 201) {
        const provider = response.data.createFFCProvider.response;

        utils.successPopupMsg(
          'Case Created',
          `Successfully created case with ID: ${provider.id}`
        );

        setFraudCase(provider);
        // processor.rehydrateHeader(provider, form, selectOptionsProps.decisionCodes, selectOptionsProps.providerTypes);
        // processor.rehydrateGrids(provider, dataGridProps);
        triggerSearch();
        // OK, so new provider created so we'll redirect the user to edit provider page for this new provider.
        navigate(`/providers/edit/${provider.id}`);
      } else {
        displayError(response?.data?.createFFCProvider);
      }
      setIsLoading(false);
    },
    onError: (err) => {
      setIsLoading(false);
      const title = err?.response?.statusText || 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
    },
  });

  const [, updateProvider] = useUpdateProvider({
    onCompleted: (response) => {
      const status = response?.data?.updateProvider.status;
      if (status === 200) {
        const updatedProvider = response.data.updateProvider.response;
        tiggerUpdatedProvider(updatedProvider);

        utils.successPopupMsg(
          'Case Updated',
          `Successfully updated case with ID: ${updatedProvider.id}`
        );

        setFraudCase(updatedProvider);
        processor.rehydrateHeader(
          updatedProvider,
          form,
          selectOptionsProps.decisionCodes,
          selectOptionsProps.providerTypes
        );
        processor.rehydrateGrids(updatedProvider, dataGridProps);
      } else {
        displayError(response?.data?.updateProvider);
      }
      setIsLoading(false);
    },
    onError: (err) => {
      setIsLoading(false);
      const title = err?.response?.statusText || 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
    },
  });
  const [, updateProviderWithNewAction] = useUpdateProvider({
    onCompleted: (response) => {
      const status = response?.data?.updateProvider.status;

      if (status === 200) {
        tiggerUpdatedProvider(response.data.updateProvider);
        utils.successPopupMsg(
          'Case Updated',
          `Successfully updated case with ID: ${response.data.updateProvider.id}`
        );

        resetPage();
      } else {
        displayError(response?.data?.updateProvider);
      }
      setIsLoading(false);
    },
    onError: (err) => {
      setIsLoading(false);
      const title = err?.response?.statusText || 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
    },
  });

  const [, createProviderWithNewAction] = useCreateFFCProvider({
    onCompleted: (response) => {
      const status = response?.data?.createFFCProvider.status;

      if (status === 201) {
        utils.successPopupMsg(
          'Case Created',
          `Successfully created case with ID: ${response?.data?.createFFCProvider?.response?.id}`
        );

        resetPage();
        setIsLoading(false);
        triggerSearch();
      } else {
        displayError(response?.data?.createFFCProvider);
      }
    },
    onError: (err) => {
      setIsLoading(false);
      const title = err?.response?.statusText || 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
    },
  });

  /**
   * Fire a 'Creating new provider' event to NESS logs
   */
  const fireCreateNessEvent = () => {
    /** publish an event to NESS */
    publishNessEvent({
      level: 'INFO',
      path: 'providers/create',
      message: 'Creating new FFC Provider',
      tags: ['FFC', 'PROVIDER', 'CREATE'],
      query: 'N/A',
      op: 'POST',
      additionalFields: {
        'user-invoked': true,
        isUIAction: true,
        event: 'CREATE-FFC-PROVIDER',
      },
    });
  };

  const minHeadersSet = (): boolean => {
    // if there's any field validation errors then we can't allow save buttons to be enabled
    if (!form?.formState.isValid) return false;

    // WatchFields
    const filled = [
      WatchFields.firstName,
      WatchFields.lastName,
      WatchFields.providerTypeCode,
      WatchFields.tipSource,
      WatchFields.presentedDate,
    ].every((value) => {
      return !isEmpty(value);
    });

    const altFilled = [
      WatchFields.orgName,
      WatchFields.providerTypeCode,
      WatchFields.tipSource,
      WatchFields.presentedDate,
    ].every((value) => {
      return !isEmpty(value);
    });

    return filled || altFilled;
  };

  useEffect(() => {
    form.trigger();
    // can we enable the save buttons
    setMinHeadersPopulated(minHeadersSet());
  }, [
    firstName,
    lastName,
    orgName,
    middleInitial,
    tipSource,
    notes,
    providerTypeCode,
    cancelMsg,
    cancelTitle,
    presentedDate,
    requestedDecisionCode,
    dollarBills,
    isLoading,
    selectOptionsProps,
    form.formState.isValid,
  ]);

  const doSave = (request) => {
    const variables = {
      body: `${JSON.stringify(request)}`,
    };
    createProvider({ variables });
  };
  const doSaveWithNewAction = (request) => {
    const variables = {
      body: `${JSON.stringify(request)}`,
    };
    createProviderWithNewAction({ variables });
  };

  const doUpdate = (request) => {
    const variables = {
      id: request.id,
      body: `${JSON.stringify(request)}`,
    };
    updateProvider({ variables });
  };
  const doUpdateWithNewAction = (request) => {
    const variables = {
      id: request.id,
      body: `${JSON.stringify(request)}`,
    };
    updateProviderWithNewAction({ variables });
  };

  const onSubmit = (data) => {
    const [valid, disallowedEntities] = processor.validateNew(
      dataGridProps.getData()
    );

    if (valid) {
      setIsLoading(true);

      // are we creating a provider?
      if (fraudCase.id == null) {
        // whatever is on the screen is valid so let's create request to send to backend
        const request: IProviderHeading = processor.buildNewRequest(
          data,
          disallowedEntities
        );
        // no record saved yet
        setLoadingMsg('Please wait while we create your provider');
        setLoadingTitle('Creating New Provider');
        doSave(request);
      } else {
        // nope, updating an existing provider
        const [, updateDisallowedEntities] = processor.validateUpdate(
          dataGridProps.getData()
        );
        const updateRequest: IProviderHeading = processor.buildUpdateRequest(
          fraudCase.id,
          data,
          updateDisallowedEntities
        );
        // ok, so we've already created the provider, now we're doing an update on it
        // has the page changed since created?
        const dirty = processor.dirty(form, dataGridProps.getData(), fraudCase);
        if (dirty) {
          // ok, just a save action and the page is dirty so update
          // utils.loadingPopupMsg('Processing', 'Please wait while we process your changes');
          setLoadingMsg('Please wait while we update your provider');
          setLoadingTitle('Updating Provider');
          doUpdate(updateRequest);
        } else {
          // if just a save action and not dirty then inform user
          utils.popupMsg(
            'Update Provider',
            'There were no changes detected so nothing to update'
          );
          setIsLoading(false);
        }
      }
    }
  };

  const onSubmitWithNewAction = (data) => {
    const [valid, disallowedEntities] = processor.validateNew(
      dataGridProps.getData()
    );

    if (valid) {
      setIsLoading(true);
      // a new provider?
      if (fraudCase.id == null) {
        // whatever is on the screen is valid so let's create request to send to backend
        const request: IProviderHeading = processor.buildNewRequest(
          data,
          disallowedEntities
        );
        // no record saved yet
        setLoadingMsg('Please wait while we create your provider');
        setLoadingTitle('Creating New Provider');
        doSaveWithNewAction(request);
      } else {
        // nope, updating an existing provider
        const [, updateDisallowedEntities] = processor.validateUpdate(
          dataGridProps.getData()
        );
        // ok, so we've already created the provider, now we're doing an update on it
        // has the page changed since created?
        const request: IProviderHeading = processor.buildUpdateRequest(
          fraudCase.id,
          data,
          updateDisallowedEntities
        );
        const dirty = processor.dirty(form, dataGridProps.getData(), fraudCase);
        if (dirty) {
          setLoadingMsg('Please wait while we update your provider');
          setLoadingTitle('Updating Provider');
          // only update if the page has changed
          doUpdateWithNewAction(request);
        } else {
          // ok, nothing to save so reset the page
          resetPage();
          setIsLoading(false);
        }
      }
    }
  };

  // Trigger one input to validate
  const triggerSaveState = () => {
    const [valid, title, message] = utils.isHeaderInfoValid(form.getValues());
    if (valid) {
      fireCreateNessEvent();
      onSubmit(form.getValues());
    } else {
      utils.popupMsg(title, message);
    }
  };
  const triggerSaveAndNewState = () => {
    const [valid, title, message] = utils.isHeaderInfoValid(form.getValues());
    if (valid) {
      fireCreateNessEvent();
      onSubmitWithNewAction(form.getValues());
    } else {
      utils.popupMsg(title, message);
    }
  };
  const triggerCancel = () => {
    if (processor.dirty(form, dataGridProps.getData(), fraudCase)) {
      setCancelTitle('Cancel Changes');
      setCancelMsg(
        'You have unsaved changes, are you sure you want to cancel?'
      );
    } else {
      setCancelTitle('Cancel');
      setCancelMsg('Are you sure you want to cancel?');
    }
    setModalOpen(true);
  };

  return (
    <Main id="providerCreatePage">
      <div
        style={{
          padding: '15px',
          margin: '10px',
          maxWidth: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {/* We look at how Volcan use this but this is only temp for now */}
        <Breadcrumbs
          // divider={'...'}
          isDarkMode
          leadingIcon={<IconMaterial icon="home" size="24px" />}
          items={[
            { title: 'Home', href: '/' },
            { title: 'Manage FFC Cases', href: '/providers/manage' },
            { title: 'Create New Provider', href: '/providers/create' },
          ]}
        />
      </div>
      <div>
        <FormProvider
          state={form}
          onSubmit={onSubmit}
          descriptorsDisplay="column"
        >
          {/* The name entry widgets */}
          <ColumnElement>
            <Header>
              <Flex>
                <TextInput
                  label="First Name"
                  errorMessage="First Name Required"
                  model="firstName"
                  validators={{
                    required: orgName?.length === 0,
                    maxLength: {
                      value: 50,
                      message: 'Max Length 50',
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_-]{1,50}$/,
                      message: 'No special characters allowed',
                    },
                  }}
                  onChange={setFirstName}
                  isClearable
                  onClear={setFirstName}
                  subText=""
                />
                {/* </div> */}
              </Flex>

              {/* </div> */}
            </Header>

            <Header>
              <TextInput
                label="Last Name"
                errorMessage="Last Name Required"
                model="lastName"
                validators={{
                  required: orgName?.length === 0,
                  maxLength: {
                    value: 50,
                    message: 'Max Length 50',
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9_-]{1,50}$/,
                    message: 'No special characters allowed',
                  },
                }}
                onChange={setLastName}
                isClearable
                onClear={setLastName}
                subText=""
              />
            </Header>

            <Header>
              <TextInput
                label="Middle Initial"
                placeholder=""
                model="middleInitial"
                onChange={setMiddleInitial}
                isClearable
                onClear={setMiddleInitial}
                validators={{
                  required: false,
                  maxLength: {
                    value: 1,
                    message: 'Max Length 1',
                  },
                  pattern: {
                    value: /^[a-zA-Z]{1}$/,
                    message: 'No special characters allowed',
                  },
                }}
                subText=""
              />
            </Header>
          </ColumnElement>

          <ColumnElement>
            <Header>
              <TextInput
                label="Org Name"
                errorMessage="Org Name Required"
                model="orgName"
                onChange={setOrgName}
                isClearable
                onClear={setOrgName}
                validators={{
                  required: lastName?.length === 0 || firstName?.length === 0,
                  maxLength: {
                    value: 150,
                    message: 'Max Length 150',
                  },
                }}
              />
            </Header>

            <Header data-cy="fpc-input-provider-type">
              <SelectInput
                label="Provider Type"
                placeholder="Select Type"
                model="providerTypeCode"
                isSearchable
                onChange={setProviderTypeCode}
                options={selectOptionsProps.providerTypes}
                validators={{ required: true }}
              />
            </Header>

            <Header>
              <TextInput
                label="Source of Tip"
                errorMessage="Source of Tip Required"
                model="tipSource"
                onChange={setTipSource}
                isClearable
                onClear={setTipSource}
                validators={{
                  required: true,
                  maxLength: {
                    value: 150,
                    message: 'Max Length 150',
                  },
                }}
              />
            </Header>
          </ColumnElement>

          <ColumnElement>
            <Header data-cy="fpc-input-requested-decision-code">
              <SelectInput
                label="Requested Decision"
                model="requestedDecisionCode"
                isSearchable
                onChange={setRequestedDecisionCode}
                placeholder={constants.HARD_DENY}
                options={selectOptionsProps.decisionCodes} // constants.ICD_CODES}
                validators={{
                  required: false,
                }}
              />
            </Header>

            <Header>
              <TextInput
                label="Dollar's Bill"
                model="dollarBills"
                onChange={setDollarBills}
                isClearable
                onClear={setDollarBills}
                type="text"
                mask="numeric"
                returnUnmaskedValue
                maskConfig={{
                  thousandSeparator: ',',
                  fixedDecimalScale: true,
                  decimalScale: 2,
                  prefix: '$',
                }}
                validators={{
                  required: false,
                  validate: {
                    notLessThanZero: (v) => {
                      return v >= 0 || 'Negative values not allowed';
                    },
                  },
                }}
              />
            </Header>

            <Header>
              <DateInput
                label="Presented Date"
                model="presentedDate"
                onChange={setPresentedDate}
                validators={{ required: true }}
              />
            </Header>
          </ColumnElement>

          {/* notes section */}
          <div
            style={{
              width: '30%',
              display: 'inline-block',
              margin: '5px',
              verticalAlign: 'top',
            }}
          >
            <TextInputArea
              label="Notes"
              model="notes"
              onChange={setNotes}
              isClearable
              onClear={setNotes}
              validators={{
                required: false,
                maxLength: {
                  value: 1_000,
                  message: 'Max Length 1000',
                },
              }}
              rows={7}
              subText={`Enter notes here: (${
                form?.getValues('notes')?.length
              }/1000) chars`}
            />
          </div>
        </FormProvider>

        {/* <FFCHeader  /> */}

        <Divider color="$primary1" />

        {/* ID table */}
        <div
          style={{
            width: '100%',
            display: 'inline-block',
            margin: '5px',
            paddingTop: '20px',
            verticalAlign: 'centre',
          }}
        >
          <DataGrid gridTitle="Disallowed Entity" gridState={dataGridProps} />
        </div>

        {/* <Divider color="$primary1" /> */}

        {/* save delete etc. */}
        <div
          style={{
            width: '85%',
            display: 'inline-block',
            margin: '5px',
            paddingTop: '40px',
            verticalAlign: 'top',
          }}
        >
          <Flex>
            <div style={{ padding: '5px', margin: '1px' }}>
              <Button
                variant="outline"
                before={<IconMaterial icon="save" color="$primary1" />}
                type="submit"
                isDisabled={!minHeadersPopulated}
                onClick={triggerSaveState}
                data-cy="fpc-btn-save"
              >
                Save
              </Button>
            </div>
            <div tabIndex={-1} style={{ padding: '5px', margin: '1px' }}>
              <Button
                variant="outline"
                before={<IconMaterial icon="save" color="$primary1" />}
                type="submit"
                isDisabled={!minHeadersPopulated}
                onClick={triggerSaveAndNewState}
                data-cy="fpc-btn-save-and-new"
              >
                Save and New
              </Button>
            </div>
            <VisuallyHidden id="reason-disabled">
              The Save buttons are disabled because form fields have invalid
              input.
            </VisuallyHidden>
            <div style={{ padding: '5px', margin: '1px' }}>
              <Button
                variant="outline"
                before={<IconMaterial icon="cancel" color="$primary1" />}
                onClick={triggerCancel}
                data-cy="fpc-btn-cancel"
              >
                Cancel
              </Button>
            </div>

            <div style={{ padding: '5px', margin: '1px' }}>
              <Popover
                title="New Case"
                content="The Save buttons will be enabled once manadatory header details are filled in. Grid fields marked &#x2731; are mandatory."
              >
                {/* <IconBrand icon="question_mark" size={40}/> */}
                <IconMaterial icon="help" size={40} />
              </Popover>
            </div>
          </Flex>
        </div>

        {/* </FormProvider> */}
        <LoadingOverlay
          loadingTitle={loadingTitle}
          loadingMessage={loadingMsg}
          ariaLoadingLabel="This is the ariaLoadingLabel prop."
          isLoading={isLoading}
        >
          <Card
            css={{
              'abyss-card-root': {
                height: 250,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '$gray4',
              },
            }}
          />
        </LoadingOverlay>

        <ConfirmationModal
          title={cancelTitle}
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setUrl('#');
          }}
          nextButton="Yes"
          backButton="No"
          message={cancelMsg}
          href="/providers/manage"
        />

        {/* <Divider color="$primary1" /> */}

        {/* <div style={{ */}
        {/*	paddingTop: '40px', */}
        {/*	verticalAlign: 'bottom', */}
        {/*	display: IS_LOCAL ? "inline-block" : "none" */}
        {/* }} > */}

        {/* </div> */}
      </div>
    </Main>
  );
};
