/*
  FROM: https://github.com/uhc-tech/abyss/blob/main/packages/abyss-web/src/ui/DataTable/SubHeaderButton/SubHeaderButton.jsx
*/

import { Button } from '@abyss/web/ui/Button';
import { styled } from '@abyss/web/tools/styled';

export const SubHeaderButton = styled(Button, {
  color: '$interactive1',
  borderRadius: '4px',
  padding: '$sm !important',
  fontWeight: '$dropDownButtonFontWeight',

  // the container for the icon + text
  '> span': {
    padding: '0 !important',
    fontSize: '$sm',

    // the before icon
    '> span': {
      paddingRight: '$xs !important',
    },
  },
  '&:hover,&:active': {
    backgroundColor: '$gray2 !important',
  },
  variants: {
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        backgroundColor: '$gray3',
        color: '$gray5',
        '.abyss-icon': {
          fill: '$gray5',
          color: '$gray5 !important',
        },
        '&:hover': {
          backgroundColor: '$gray3 !important',
        },
      },
    },
  },
});
