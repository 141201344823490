/* eslint-disable func-names */
/* eslint-disable arrow-body-style */

type DateProxyHandler = {
  get(target: Date, property: keyof Date): any;
};

export function createProxyDate(dateStr: string, displayText: string = dateStr): Date {
  const proxyDate = new Date(dateStr);

  const handler: DateProxyHandler = {
    get(target, property) {
      if (property === 'toString') {
        return function () {
          return displayText; // Custom string override
        };
      }
      if (property === 'getTime') {
        return function () {
          return target.getTime();
        };
      }
      if (property === Symbol.toPrimitive) {
        return function(hint: string) {
          if (hint === 'string') {
            return displayText;
          }
          if (hint === 'number') {
            return target.getTime();
          }
          return target.getTime();
        };
      }
      const value = target[property];
      if (typeof value === 'function') {
        return function(...args: any[]) {
          return value.apply(target, args);
        };
      }
      return value;
    }
  };

  return new Proxy(proxyDate, handler) as unknown as Date;
}

/** This is our EPOCH date = a long, long time ago */
export const REALLY_OLD_DATE_UTC = createProxyDate('0001-12-25T00:00:00Z', '');
