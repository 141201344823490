/* eslint-disable @typescript-eslint/no-use-before-define */
let uid = new Date().getTime();

export function getUid() {
  uid += 1;

  return hasCrypto() ? `a${window.crypto.randomUUID()}` : String(uid);

  function hasCrypto() {
    return !!window?.crypto?.randomUUID;
  }
}
