/* eslint-disable arrow-body-style */
import React from 'react';
import {
  eventSearchResultsApi,
  useEventSearchResultsStore,
} from '../../mothership/eventSearchResults.state';

export const EventSearchResultsChartBarTooltip = () => {
  const eventSummary = useEventSearchResultsStore(
    (state) => state.hoveredEventSummary
  );
  if (!eventSummary) {
    return null;
  }

  const { dateFormatted, events } = eventSummary;
  const { setElement } = eventSearchResultsApi.chartTooltip;

  return (
    <div
      data-cy="eventSearchResultsChartBarTooltip"
      data-testid="eventSearchResultsChartBarTooltip"
      className="dfedsTooltip"
      ref={setElement}
    >
      <div className="dfedsTooltipHeader">{dateFormatted}</div>
      {events.map((event) => (
        <div key={event.eventTypeMeta.label} className="dfedsTooltipEventItem">
          <div className="dfedsTooltipEventItemLabel">
            {event.eventTypeMeta.label}
          </div>
          <div className="dfedsTooltipEventItemValue">
            {event.countFormatted}
          </div>
        </div>
      ))}
    </div>
  );
};
