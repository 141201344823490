/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-use-before-define */
export function generateYTics(
  yMin: number,
  yMax: number,
  approxNumberOfTics: number
): number[] {
  const rawInterval = (yMax - yMin) / (approxNumberOfTics - 1);
  const niceInterval = niceRoundNumber(rawInterval);

  // Adjust min and max to align with the nice interval
  const ticStart = Math.floor(yMin / niceInterval) * niceInterval;
  const ticEnd = Math.ceil(yMax / niceInterval) * niceInterval;

  const tics: number[] = [];
  for (let tic = ticStart; tic <= ticEnd; tic += niceInterval) {
    tics.push(tic);
  }

  return tics;
}

function niceRoundNumber(value: number): number {
  // Determine order of magnitude
  const exponent = Math.floor(Math.log10(value));
  const fraction = value / Math.pow(10, exponent);

  // Round fraction to the nearest 1, 2, or 5
  let niceFraction: number;
  if (fraction < 1.5) {
    niceFraction = 1;
  } else if (fraction < 3) {
    niceFraction = 2;
  } else if (fraction < 7) {
    niceFraction = 5;
  } else {
    niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
}
