/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { getReasonPhrase } from 'http-status-codes';
import { getCachedDate } from "../../../../../common/util/dates";
import { useGetCmsGlobalSearch } from '../../../../../hooks/useCmsGlobalSearch';
import { constants } from '../../../common/constants';

export const CmsResults = ({
  query,
  updateCmsTabLabel,
  drawer,
  setDrawerTitle,
  onLoaded,
}) => {
  const [noCmsDataMsg, setNoCmsDataMsg] = useState('No Matching Records');
  const [cmsTableData, setCmsTableData] = useState([]);
  const [, getCmsProviders] = useGetCmsGlobalSearch({
    onCompleted: async (response) => {
      try {
        const status = response?.data?.cmsGlobalSearch?.status;

        if (status === 200) {
          let ps = response?.data?.cmsGlobalSearch?.data || [];
          ps = ps?.map((p) => {
            return {
              ...p,
              claimRejectionDate: getCachedDate(p.claimRejectionDate),
              dob: getCachedDate(p.dob),
              exclusionDate: getCachedDate(p.exclusionDate),
              originalModifiedDate: getCachedDate(p.originalModifiedDate),
              reinstatedDate: getCachedDate(p.reinstatedDate),
            };
          });

          setCmsTableData(ps);
          updateCmsTabLabel(`CMS (${ps.length})`);
        } else {
          // got an error
          const error = response?.data?.cmsGlobalSearch;
          const reason = getReasonPhrase(error?.status);
          setNoCmsDataMsg(`No Matching Records / ${reason} - ${error?.detail}`);
          updateCmsTabLabel('CMS (0)');
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('provider-global-search:Exception:%s', e);
        updateCmsTabLabel('CMS (0)');
      } finally {
        onLoaded({
          source: constants.SOURCE_CMS,
          action: {
            type: 'loaded',
            loading: false,
          },
        });
      }
    },
    onError: (err) => {
      updateCmsTabLabel('CMS (0)');
      const title = err?.type || 'Unexpected error!';
      const message = err?.message || 'An unexpected error occurred!';
      console.error(`Error pulling back FFC providers : ${title}, ${message}`);
      setNoCmsDataMsg(`No Matching Records / ${title}, ${message}`);

      onLoaded({
        source: constants.SOURCE_CMS,
        action: {
          type: 'loaded',
          loading: false,
        },
      });
    },
  });

  const cmsGridData = React.useMemo(() => {
    return [...cmsTableData];
  }, [cmsTableData]);

  const cmsColumns = React.useMemo(() => {
    return [
      {
        Header: 'Last Name',
        // isRowHeader: true,
        accessor: 'lastName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: false,
        maxWidth: 120,
        hiddenDefaultFilters: ['greater'],
      },
      {
        Header: 'First Name',
        // isRowHeader: true,
        accessor: 'firstName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: false,
        maxWidth: 120,
        hiddenDefaultFilters: ['greater'],
      },
      {
        Header: 'Org Name',
        accessor: 'orgName',
        maxWidth: 150,
        canToggleVisibilty: false,
        canReorderColumn: false,
        disableSortBy: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'General',
        accessor: 'general',
        maxWidth: 250,
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: false,
      },
      {
        Header: 'Specialty',
        accessor: 'specialty',
        canToggleVisibilty: false,
        canReorderColumn: false,
        disableSortBy: false,
        isHiddenByDefault: false,
        maxWidth: 255,
      },
      {
        Header: 'NPI',
        accessor: 'identifierValue',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: false,
        maxWidth: 130,
      },
      {
        Header: 'EIN',
        accessor: 'ein',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: false,
        maxWidth: 130,
      },
      {
        Header: 'State',
        accessor: 'state',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: false,
        maxWidth: 120,
      },
      {
        Header: 'Exc Date',
        accessor: 'exclusionDate',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: false,
        maxWidth: 120,
        sortType: 'datetime',
        Cell: ({ value }) => {
          return value.toString();
        },
      },
      {
        Header: 'Clm Rej Date',
        accessor: 'claimRejectionDate',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 120,
        sortType: 'datetime',
        Cell: ({ value }) => {
          return value.toString();
        },
      },
      {
        Header: 'Reinst Date',
        accessor: 'reinstatedDate',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 120,
        sortType: 'datetime',
        Cell: ({ value }) => {
          return value.toString();
        },
      },
      {
        Header: 'Reasons',
        accessor: 'reasons',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 150,
        Cell: ({ value }) => {
          const isArray = Array.isArray(value);
          if (isArray && !value.length) return '';
          if (isArray && value.length === 1) return value;
          return (
            <Layout.Group css={{ justifyContent: 'space-between' }}>
              {isArray ? value[0] : value}
              {isArray && (
                <Link
                  size="$sm"
                  onClick={() => {
                    setDrawerTitle('Reasons');
                    drawer.open({ value });
                  }}
                  aria-haspopup="dialog"
                >
                  more...
                </Link>
              )}
            </Layout.Group>
          );
        },
      },
      {
        Header: 'DOB',
        accessor: 'dob',
        canToggleVisibilty: true,
        isHiddenByDefault: true,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 120,
        sortType: 'datetime',
        Cell: ({ value }) => {
          return value.toString();
        },
      },
      {
        Header: 'Original Last Updated',
        accessor: 'originalModifiedDate',
        canToggleVisibilty: true,
        isHiddenByDefault: true,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 150,
        sortType: 'datetime',
        Cell: ({ value }) => {
          return value.toString();
        },
      },
      {
        Header: 'Address 1',
        accessor: 'addressLine1',
        canToggleVisibilty: true,
        isHiddenByDefault: true,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 150,
      },
      {
        Header: 'Address 2',
        accessor: 'addressLine2',
        canToggleVisibilty: true,
        isHiddenByDefault: true,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 150,
      },
      {
        Header: 'City',
        accessor: 'city',
        canToggleVisibilty: true,
        isHiddenByDefault: true,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 150,
      },
      {
        Header: 'ZIP Code',
        accessor: 'postalCode',
        canToggleVisibilty: true,
        isHiddenByDefault: true,
        disableSortBy: false,
        canReorderColumn: true,
        maxWidth: 120,
      },
    ];
  }, [cmsGridData]);
  const cmsDataTableProps = useDataTable({
    initialData: cmsGridData,
    initialColumns: cmsColumns,
    showSelection: false,
    showPagination: true,
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: false,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
    showColumnVisibilityConfig: true,
    noDataMessage: noCmsDataMsg,
    uniqueStorageId: 'cms-data-table-usage',
    pageSizeOptions: constants.DEFAULT_PAGE_SIZE_OPTIONS,
  });

  useEffect(() => {
    const variables = {
      filter: {
        ...query,
      },
    };

    getCmsProviders({ variables });
  }, []);

  useEffect(() => {
    cmsDataTableProps.setData(cmsTableData, true);
  }, [cmsTableData]);

  return (
    <React.Fragment>
      <DataTable
        tableState={cmsDataTableProps}
        key="cmsDataTableProps"
        data-cy="gpsr-table-cms"
      />
    </React.Fragment>
  );
};
