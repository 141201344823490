/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable arrow-body-style */

import { RouteUrl } from './routeUrl';

type Observer = () => Promise<void>;

export const routeObserver = routeObserverFn();

function routeObserverFn() {
  const observables = {
    eventSearch: async () => {},
  };

  return {
    routeChanged,
    observeEventSearch,
  };

  async function routeChanged(pathname: string) {
    if (pathname === RouteUrl.eventsSearch) {
      await observables.eventSearch();
    }
  }

  function observeEventSearch(observer: Observer) {
    observables.eventSearch = observer;
  }
}
