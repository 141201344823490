import { EventType, EventTypeMeta } from '../types';

const eventTypeMap = {
  [EventType.unknown]: {
    label: '',
    color: '',
  },
  [EventType.providerEnrollment]: {
    label: 'Provider Enrollment',
    color: '#C3393E',
  },
  [EventType.billingServiceEnrollment]: {
    label: 'Billing Service Enrollment',
    color: '#E19415',
  },
  [EventType.passwordChange]: {
    label: 'Password Change',
    color: '#577C32',
  },
  [EventType.login]: {
    label: 'Login',
    color: '#2F6FED',
  },
  [EventType.primaryBankChange]: {
    label: 'Primary Bank Change',
    color: '#2A9D8F',
  },
  [EventType.altBankChange]: {
    label: 'Alternate Bank Change',
    color: '#F46F27',
  },
  [EventType.phoneNumberChange]: {
    label: 'Phone Number Change',
    color: '#1E45A7',
  },
  [EventType.mailAddressChange]: {
    label: 'Physical Address Change',
    color: '#A4812C',
  },
  [EventType.emailChange]: {
    label: 'Email Address Change',
    color: '#8A54F7',
  },
};

export function getEventTypeMeta(eventType: EventType): EventTypeMeta {
  const defaultMeta = {
    label: eventType,
    color: '#ccc',
  };
  const meta = eventTypeMap[eventType];
  return meta ?? defaultMeta;
}
