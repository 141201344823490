/* eslint-disable arrow-body-style */
import React from 'react';
import { useEventSearchResultsStore } from '../../../mothership/eventSearchResults.state';

export const ResultsTableHead = () => {
  const headers = useEventSearchResultsStore((state) => {
    return state.tableHeader;
  });

  return (
    <thead>
      <tr>
        {headers.map((item) => (
          <th key={item}>{item}</th>
        ))}
      </tr>
    </thead>
  );
};
