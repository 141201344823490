import { EventItem, EventType } from './types';

export const defaultEventItem: EventItem = {
  externalTraceId: '',
  clientId: '',
  eventType: EventType.unknown,
  accountSourceType: '',
  accountId: '',
  eventDateTime: '',
  ipAddress: '',
  askId: '',
  dataSource: '',
  internalTraceId: '',
  sourceReportVersion: '',
  ipState: '',
  ipCountry: '',
  ipCountryCode: '',
};
