/* eslint-disable no-return-assign */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Drawer } from '@abyss/web/ui/Drawer';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import {
  eventSearchResultsApi,
  useEventSearchResultsStore,
} from '@src/routes/events/search/mothership/eventSearchResults.state';
import { eventItemDetailLabels } from '../../../EventItemDetailLabels';
import { ColumnSelectionToggle } from './ColumnSelectionToggle';
import { ColumnSelectionLocked } from './ColumnSelectionLocked';

export const ColumnSelectorDrawer = () => {
  const open = useEventSearchResultsStore(
    (state) => state.showTableColSelector
  );
  const tableColVisibility = useEventSearchResultsStore(
    (state) => state.tableColVisibility
  );
  const allVisible = Object.values(tableColVisibility).every(
    (checked) => checked
  );

  return (
    <Drawer
      title="Column Selection"
      isOpen={open}
      onClose={eventSearchResultsApi.hideTableColSelector}
      css={{
        'abyss-modal-content-container': {
          minWidth: '36%',
        },
      }}
    >
      <div
        className="eventSearchColumnSelector"
        data-cy="eventSearchColumnSelector"
        data-testid="eventSearchColumnSelector"
      >
        <p className="eventSearchColumnSelectorInstructions">
          You can click the eye icon to toggle a column as visible or invisible.
          If there is a lock by the column, then you cannot toggle the
          visibility of that column.
        </p>

        <div className="eventSearchColumnSelectorToggleContainer">
          <ToggleSwitch
            label="Toggle All Columns"
            isChecked={allVisible}
            onChange={eventSearchResultsApi.toggleAllVisibleTableCols}
            css={{
              'abyss-toggle-switch-root': { marginRight: '$md' },
            }}
          />
        </div>

        <div>
          <div className="eventSearchColHeader">Table Columns</div>
          <ColumnSelectionLocked label={eventItemDetailLabels.eventType} />
          <ColumnSelectionLocked label={eventItemDetailLabels.eventDateTime} />
          <ColumnSelectionLocked label={eventItemDetailLabels.ipAddress} />
          <ColumnSelectionLocked label={eventItemDetailLabels.clientId} />
          <ColumnSelectionLocked
            label={eventItemDetailLabels.accountSourceType}
          />
          <ColumnSelectionLocked label={eventItemDetailLabels.accountId} />
          <ColumnSelectionToggle
            checked={tableColVisibility.externalTraceId}
            label={eventItemDetailLabels.externalTraceId}
            setter={(checked) => (tableColVisibility.externalTraceId = checked)}
          />
          <ColumnSelectionToggle
            checked={tableColVisibility.askId}
            label={eventItemDetailLabels.askId}
            setter={(checked) => (tableColVisibility.askId = checked)}
          />
          <ColumnSelectionToggle
            checked={tableColVisibility.dataSource}
            label={eventItemDetailLabels.dataSource}
            setter={(checked) => (tableColVisibility.dataSource = checked)}
          />
          <ColumnSelectionToggle
            checked={tableColVisibility.internalTraceId}
            label={eventItemDetailLabels.internalTraceId}
            setter={(checked) => (tableColVisibility.internalTraceId = checked)}
          />
          <ColumnSelectionToggle
            checked={tableColVisibility.sourceReportVersion}
            label={eventItemDetailLabels.sourceReportVersion}
            setter={(checked) =>
              (tableColVisibility.sourceReportVersion = checked)
            }
          />
          <ColumnSelectionToggle
            checked={tableColVisibility.ipState}
            label={eventItemDetailLabels.ipState}
            setter={(checked) => (tableColVisibility.ipState = checked)}
          />
          <ColumnSelectionToggle
            checked={tableColVisibility.ipCountry}
            label={eventItemDetailLabels.ipCountry}
            setter={(checked) => (tableColVisibility.ipCountry = checked)}
          />
          <ColumnSelectionToggle
            checked={tableColVisibility.ipCountryCode}
            label={eventItemDetailLabels.ipCountryCode}
            setter={(checked) => (tableColVisibility.ipCountryCode = checked)}
          />
        </div>
      </div>
    </Drawer>
  );
};
