/* eslint-disable @typescript-eslint/no-use-before-define */
import { useToast } from '@abyss/web/hooks/useToast';
import { isEmpty, groupBy } from 'lodash';
import { constants } from './constants';
import { constraints } from './constraints';

const { toast } = useToast();

// TODO; we need to create tests
/** simple helper type used during validation to hold result and a message */
type TCheckResponse = {
  valid: boolean;
  message: string;
};

/** Simple type to represent the header */
export interface IProviderHeading {
  id?: number;
  firstName: string;
  middleInitial: string;
  lastName: string;
  providerTypeCode: string;
  orgName: string;
  requestedDecisionCode: string;
  presentedDate: string;
  dollarBills: string;
  tipSource: string;
  notes: string;
  identifiers: Array<IProviderDisallowedEntity>;
}

/** Simple type to represent the disallowed entity - essentially a data grid row */
export interface IProviderDisallowedEntity {
  id?: number;
  idTypeCode: string;
  idTypeValue: string;
  name: string;
  address1?: string;
  address2?: string;
  country: string;
  stateCode: string;
  city?: string;
  postalCode?: string;
  decisionCode: string;
  procedureCode: string;
  decisionDate: string;
  decisionReasonCode: number;
  decisionRemovalDate: string;
  decisionRemovalReason?: string;
}

const popupMsg = (title, message) => {
  toast.show({
    title,
    message,
    // autoClose: false,
    autoClose: 3000,
  });
};

const loadingPopupMsg = (title, message) => {
  toast.show({
    title,
    message,
    isLoading: true,
    ariaLoadingLabel: 'Loading example',
    variant: 'info',
    autoClose: 1000,
  });
};

const successPopupMsg = (title, message) => {
  toast.show({
    title,
    message,
    variant: 'success',
    autoClose: 3000,
  });
};

/**
 * Checks the constraints on the ID type and it's associated value
 * @param id the id type name
 * @param val the id type value
 */
const validateIDTypeAndValueConstraints = (
  id: string,
  val: string
): TCheckResponse => {
  if (id === 'NPI' && val?.length !== 10)
    return { valid: false, message: 'NPI value must be 10 digits.' };

  if (id === 'MPIN' && (val?.length < 5 || val?.length > 8))
    return { valid: false, message: 'MPIN value must be between 5-8 digits.' };

  if (id === 'TIN' && val?.length !== 9)
    return { valid: false, message: 'TIN value must be 9 digits.' };

  return { valid: true, message: 'ID Value Validated' };
};

const toNullIfAllowed = (p: string): string | null => {
  return isEmpty(p) ? null : p.trim();
};

function fromDataGridRowToNewIdentity(row): IProviderDisallowedEntity {
  const procedureCodes = row[9] === constants.HDCS_CODE ? row[10] : null;
  return {
    idTypeCode: row[1], // required
    idTypeValue: row[2], // required
    name: toNullIfAllowed(row[3]),
    address1: toNullIfAllowed(row[4]),
    address2: toNullIfAllowed(row[5]),
    stateCode: row[6],
    city: toNullIfAllowed(row[7]),
    postalCode: toNullIfAllowed(row[8]),
    decisionCode: row[9], // required
    procedureCode: procedureCodes,
    decisionDate: row[11], // required
    decisionReasonCode: row[12], // required
    decisionRemovalDate: toNullIfAllowed(row[13]),
    decisionRemovalReason: toNullIfAllowed(row[14]),
  };
}

function fromDataGridRowToIdentity(row): IProviderDisallowedEntity {
  return {
    id: row[0],
    ...fromDataGridRowToNewIdentity(row),
  };
}
const toDisallowedEntity = (row): IProviderDisallowedEntity => {
  return {
    ...fromDataGridRowToIdentity(row),
  };
};
const toNewDisallowedEntity = (row): IProviderDisallowedEntity => {
  return {
    ...fromDataGridRowToNewIdentity(row),
  };
};

/**
 * Maps the data grid rows to our Type for easier handling.
 * It will not process any rows that can be ignored
 * @param gridData all the rows from the data grid
 */
const toJson = (gridData): [IProviderDisallowedEntity] => {
  return gridData
    .filter((row) => {
      return !ignorableEntity(row);
    })
    .map((row) => {
      return toDisallowedEntity(row);
    });
};

const toCreateJson = (gridData): [IProviderDisallowedEntity] => {
  return gridData
    .filter((row) => {
      return !ignorableEntity(row);
    })
    .map((row) => {
      return toNewDisallowedEntity(row);
    });
};

const toDataGridRow = (json: IProviderDisallowedEntity) => {
  return [
    json.id,
    json.idTypeCode,
    json.idTypeValue,
    json.name,
    json.address1,
    json.address2,
    // json.country,
    json.stateCode,
    json.city,
    json.postalCode,
    json.decisionCode,
    json.decisionCode === 'HDCS' ? json.procedureCode : 'N/A for non HDCS',
    json.decisionDate,
    json.decisionReasonCode,
    json.decisionRemovalDate,
    json.decisionRemovalReason,
  ];
};

/** simple help type to allow reporting row that issue is found on */
interface ProviderAndRowTuple {
  identity: IProviderDisallowedEntity;
  row: number;
}

type ValidationResponse = {
  valid: boolean;
};

/**
 * Some fields on the header and data grid are mandatory - is it empty, constraints on the length etc.
 * @param text the text to check
 * @param len the length constraint to check
 */
const requiredChecks = (text: string, len: number): TCheckResponse => {
  if (isEmpty(text))
    return { valid: false, message: 'Value must not be empty' };
  return optionalChecks(text, len);
};

/**
 * Some fields are optional so the checks aren't as rigorous
 * @param text the text to check
 * @param len the length constraint to check
 */
const optionalChecks = (text: string, len: number): TCheckResponse => {
  if (text == null) return { valid: true, message: 'Allow empty value' };
  if (text.length > len)
    return { valid: false, message: `Value must not exceed ${len} chars` };
  if (text.includes('--'))
    return { valid: false, message: `Value must not contain "--"` };
  return { valid: true, message: 'Validated!' };
};

/**
 * Essentially as far as we're concerned if the following fields are empty then we will
 * consider this entity ignorable for processing
 * @param disallowedEntity the object to check if it can be ignored for processing
 */
const ignorableEntity = (disallowedIdentity: Array<any>): boolean => {
  const len = disallowedIdentity.length;
  // Depending on whether it's a create or update the array may be 15 or 16 elements - update would have the extra id field
  // so move backwards to find the fields that are mandatory
  // disallowedIdentity[len-14] // id type
  // disallowedIdentity[len-13] // id value
  // disallowedIdentity(disallowedIdentity[len-6]) // state
  // disallowedIdentity[len-6] // decisionCode eg HDCS
  // disallowedIdentity[len-5] // proc codes
  // disallowedIdentity[len-4] // decision date
  // disallowedIdentity[len-3] // decision Reason Code
  // moving back
  const empty =
    isEmpty(disallowedIdentity[len - 14]) &&
    isEmpty(disallowedIdentity[len - 9]) &&
    isEmpty(disallowedIdentity[len - 6]) &&
    isEmpty(disallowedIdentity[len - 5]) &&
    isEmpty(disallowedIdentity[len - 4]) &&
    isEmpty(disallowedIdentity[len - 3]);
  return empty;
};

/**
 * Filters out any grid data rows that are essentially ignorable - no mandatory fields filled out
 * @param allGridData all the rows that appear on the grid - this includes empty rows, partially filled etc.
 */
const persistableGridData = (allGridData) => {
  return allGridData?.filter((row) => {
    return !ignorableEntity(row);
  });
};

// collect all the various lengths associated with fields in one place
const HEADER_FIELDS_MAX_LEN = {
  firstName: 50,
  lastName: 50,
  orgName: 150,
  providerTypeCode: 10,
  tipSource: 150,
  notes: 1_000,
  middleInitial: 1,
  presentedDate: 50,
  requestedDecisionCode: 5,
};

/**
 * Validates that all the header info against known criteria
 *
 * @param data the header info
 */
const isHeaderInfoValid = (
  data: IProviderHeading
): [boolean, String, String] => {
  // if orgName is empty then first and last names must be given
  if (isEmpty(data.orgName)) {
    if (isEmpty(data.firstName) || isEmpty(data.lastName)) {
      return [
        false,
        'Missing First/Last name(s)',
        'Either combination of First/Last names, just Org name or all three',
      ];
    }
    if (
      !requiredChecks(data.firstName, HEADER_FIELDS_MAX_LEN.firstName).valid
    ) {
      return [
        false,
        'First name',
        requiredChecks(data.firstName, HEADER_FIELDS_MAX_LEN.firstName).message,
      ];
    }
    if (!requiredChecks(data.lastName, HEADER_FIELDS_MAX_LEN.lastName).valid) {
      return [
        false,
        'Last name',
        requiredChecks(data.lastName, HEADER_FIELDS_MAX_LEN.lastName).message,
      ];
    }
  } else if (
    !requiredChecks(data.orgName, HEADER_FIELDS_MAX_LEN.orgName).valid
  ) {
    return [
      false,
      'Org Name',
      requiredChecks(data.orgName, HEADER_FIELDS_MAX_LEN.orgName).message,
    ];
  }

  let res: TCheckResponse = requiredChecks(
    data.providerTypeCode,
    HEADER_FIELDS_MAX_LEN.providerTypeCode
  );
  if (!res.valid) {
    return [false, 'Provider Type', res.message];
  }
  res = requiredChecks(data.tipSource, HEADER_FIELDS_MAX_LEN.tipSource);
  if (!res.valid) {
    return [false, 'Source of Tip', res.message];
  }
  res = optionalChecks(data.notes, HEADER_FIELDS_MAX_LEN.notes);
  if (!res.valid) {
    return [false, 'Notes', res.message];
  }
  res = optionalChecks(data.middleInitial, HEADER_FIELDS_MAX_LEN.middleInitial);
  if (!res.valid) {
    return [false, 'Middle Initial', res.message];
  }
  res = optionalChecks(data.presentedDate, HEADER_FIELDS_MAX_LEN.presentedDate);
  if (!res.valid) {
    return [false, 'Presented Data', res.message];
  }
  res = optionalChecks(
    data.requestedDecisionCode,
    HEADER_FIELDS_MAX_LEN.requestedDecisionCode
  );
  if (!res.valid) {
    return [false, 'Requested Decision', res.message];
  }

  return [res.valid, 'Success', 'Header details validated'];
};

/**
 * To validate reason codes and various other options allowed on the grid we need a helper
 * that can holds these so that we don't try and pass down props everywhere
 */
const GridPropsHelper = {
  // will hold codes for diff context - ffc, whatever
  reasonsCodes: {},
};

/**
 * Sets the reason codes that are allowed for the given context - ffc etc.
 * @param context we're only supporting `ffc` for now
 * @param codes the codes that are allowed
 */
const setAllowedReasonsCodes = (context: string, codes: [number]): void => {
  GridPropsHelper.reasonsCodes = {
    ...GridPropsHelper.reasonsCodes,
    [context]: codes,
  };
};

/**
 * validates all the fields that make up a row in data grid that holds identities
 * @param tuple the diallowed entity and the row it's appears on (from the user's perspective)
 */
const validatedDataGridRow = (tuple: ProviderAndRowTuple): boolean => {
  // must have an ID value
  // we may have been sent a blank row - user is only saving header info!!
  if (isEmpty(tuple.identity.idTypeCode)) {
    popupMsg(`Invalid 'ID Type' on row ${tuple.row}`, 'ID Type must be set');
    return false;
  }
  let res: ValidationResponse;

  if (!isEmpty(tuple.identity.idTypeCode)) {
    res = requiredChecks(tuple.identity.idTypeValue, 150);
    if (!res.valid) {
      popupMsg(`Invalid 'ID Value' on row ${tuple.row}`, res.message);
      return false;
    }
  }
  res = validateIDTypeAndValueConstraints(
    tuple.identity.idTypeCode,
    tuple.identity.idTypeValue
  );
  if (!res.valid) {
    popupMsg(`Invalid 'ID Value' on row ${tuple.row}`, res.message);
    return false;
  }
  res = optionalChecks(tuple.identity.name, 150);
  if (!res.valid) {
    popupMsg(`Invalid 'Name' Value on row ${tuple.row}`, res.message);
    return false;
  }
  res = optionalChecks(tuple.identity.address1, 150);
  if (!res.valid) {
    popupMsg(`Invalid 'Address 1' Value on row ${tuple.row}`, res.message);
    return false;
  }
  res = optionalChecks(tuple.identity.address2, 150);
  if (!res.valid) {
    popupMsg(`Invalid 'Address 2' Value on row ${tuple.row}`, res.message);
    return false;
  }

  res = requiredChecks(tuple.identity.stateCode, 2);
  if (!res.valid) {
    popupMsg(`Invalid 'State' value on row ${tuple.row}`, res.message);
    return false;
  }

  res = optionalChecks(tuple.identity.city, 150);
  if (!res.valid) {
    popupMsg(`Invalid 'City' Value on row ${tuple.row}`, res.message);
    return false;
  }
  res = optionalChecks(tuple.identity.postalCode, 150);
  if (!res.valid) {
    popupMsg(`Invalid 'ZIP Code' Value on row ${tuple.row}`, res.message);
    return false;
  }
  res = optionalChecks(tuple.identity.decisionRemovalReason, 150);
  if (!res.valid) {
    popupMsg(
      `Invalid 'Reason For Approved Decision' Value on row ${tuple.row}`,
      res.message
    );
    return false;
  }
  if (tuple.identity.decisionReasonCode == null) {
    popupMsg(
      `Invalid 'Reason for Removal' Value on row ${tuple.row}`,
      res.message
    );
    return false;
  }
  // we only allow icd codes to go across if the approver decision is `HDCS`
  if (tuple.identity.decisionCode !== constants.HDCS_CODE) {
    // eslint-disable-next-line no-param-reassign
    tuple.identity.icds = undefined;
  }
  res = requiredChecks(tuple.identity.decisionCode, 50);
  if (!res.valid) {
    popupMsg(`Invalid 'Approved Descision' on row ${tuple.row}`, res.message);
    return false;
  }
  res = requiredChecks(tuple.identity.decisionDate, 150);
  if (!res.valid) {
    popupMsg(
      `Invalid 'Approved Decision Date' on row ${tuple.row}`,
      res.message
    );
    return false;
  }

  // if (!constants.REASON_IDS.includes(tuple.identity.decisionReasonCode)) {
  if (
    // eslint-disable-next-line @typescript-eslint/dot-notation
    !GridPropsHelper.reasonsCodes['ffc'].includes(
      tuple.identity.decisionReasonCode
    )
  ) {
    popupMsg(
      'Invalid Reason For Approved Decision',
      `Missing value on row ${tuple.row}`
    );
    return false;
  }

  if (tuple.identity.decisionCode === constants.HDCS_CODE) {
    const procedureCodes = tuple.identity?.procedureCode;
    const { valid, message } = constraints.procedureCodes.valid(procedureCodes);
    if (!valid) {
      popupMsg('Procedure Codes', `${message} on row ${tuple.row}`);
      return false;
    }
  }
  // Phew! All validation has passed!
  return true;
};

/**
 * We don't allow 2 or more data grid rows to have the same type id and type value.
 * @param dataGridRows the collection of rows that make up the data grid
 */
const duplicateIDTypesValues = (
  dataGridRows?: [IProviderDisallowedEntity]
): [boolean, Array<number>] => {
  let idTypeValueByRow = [];

  dataGridRows?.forEach((value, index) => {
    idTypeValueByRow = [
      ...idTypeValueByRow,
      {
        tag: `${value.idTypeCode}${value.idTypeValue}`,
        row: index,
      },
    ];
  });

  const groupByIDAndValue = groupBy(idTypeValueByRow, (v) => {
    return `${v.tag}`;
  });

  const duplicates =
    Object.keys(groupByIDAndValue).length !== dataGridRows.length;

  const dupsOnRows = Object.values(groupByIDAndValue)
    ?.find((value) => {
      return value.length > 1;
    })
    ?.map((d) => {
      return d.row;
    });

  return [duplicates, dupsOnRows];
};

const isValid = (entities: [IProviderDisallowedEntity]): boolean => {
  // not allowing duplicate ID Type/Values in different rows
  const [duplicates, onRows] = duplicateIDTypesValues(entities);

  if (duplicates) {
    popupMsg(
      `Duplicate ID Type & ID Value Not Allowed`,
      `Found on ${onRows.map((r) => {
        return `Row ${r + 1}`;
      })}`
    );
    return false;
  }
  // now do validation on the fields in each row
  // eslint-disable-next-line no-restricted-syntax
  for (const [rowIndex, providerIdentity] of entities?.entries()) {
    // we use 'rowIndex + 1' cos the user refers to row 1,2,3... and not row 0,1,2...!
    if (
      !validatedDataGridRow({ identity: providerIdentity, row: rowIndex + 1 })
    )
      return false;
  }
  return true;
};

export const utils = {
  // below are used during testing
  fromDataGridRowToNewIdentity,
  fromDataGridRowToIdentity,
  // below are exposed to all
  toNullIfAllowed,
  ignorableEntity,
  persistableGridData,
  successPopupMsg,
  loadingPopupMsg,
  popupMsg,
  toDataGridRow,
  toJson,
  toCreateJson,
  isHeaderInfoValid,
  isValid,
  setAllowedReasonsCodes,
  validateIDTypeAndValueConstraints,
};
