/* eslint-disable @typescript-eslint/no-use-before-define */

import { FilterPanelState } from '../types';

export const filterPanelChangeManager = filterPanelChangeManagerFn();

function filterPanelChangeManagerFn() {
  const committedState = {
    hash: '',
    blocked: true,
  };

  return {
    isSubmittable,
    commitState,
    setSubmitBlocked,
  };

  function isSubmittable(state: FilterPanelState) {
    if (committedState.blocked) {
      return false;
    }

    return hasChanged(state);
  }

  function commitState(state: FilterPanelState) {
    committedState.hash = getHash(state);
  }

  function setSubmitBlocked(blocked: boolean) {
    committedState.blocked = blocked;
  }

  function hasChanged(state: FilterPanelState) {
    const hash = getHash(state);
    return hash !== committedState.hash;
  }

  function getHash({
    month,
    portals,
    events,
    idType,
    value,
  }: FilterPanelState) {
    return `${month}|${portals.sort().join()}|${events
      .sort()
      .join()}|${idType}|${value}`;
  }
}
