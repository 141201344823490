import { PagedContent, SelectOption } from '@src/common/types';
import { TicsAndBarHeights } from '@src/common/charts/types';
import { __EventItemDetail } from './EventItemDetail';

export interface EventCountItem {
  count: number;
  eventType: EventType;
}
export interface EventCount {
  date: string;
  totalEvents: number;
  events: EventCountItem[];
}

export enum ResultsStatus {
  pendingSearch = 'pendingSearch',
  searching = 'searching',
  noResults = 'noResults',
  results = 'results',
  error = 'error',
}

// TODO: Confirm with backend
export enum Portal {
  pay = 'OP',
  bank = 'OB',
}
export interface EventTypePortal {
  portal: Portal;
  eventTypes: EventType[];
}

export interface EventTypePortalItem {
  name: string;
  code: EventType;
  portalCode: Portal;
}

// TODO: - full list required from backend
export enum EventType {
  unknown = '',
  providerEnrollment = 'NEW_PROVIDER_ENROL',
  billingServiceEnrollment = 'NEW_BS_PROVIDER_ENROL',
  passwordChange = 'PASSWORD_CHANGE',
  login = 'LOGIN',
  primaryBankChange = 'PRIMARY_BANK_CHANGE',
  altBankChange = 'ALT_BANK_CHANGE',
  phoneNumberChange = 'PHONE_CHANGE',
  mailAddressChange = 'MAIL_ADDRESS_CHANGE',
  emailChange = 'EMAIL_CHANGE',
}

export enum IdType {
  none = '',
  ipAddress = 'ipAddress',
  accountId = 'accountId',
}

export type IdTypeSelectOption = { label: string; value: IdType };

type NullableStoreEventDetail = {
  event: EventItemDetail;
  eventRenderable: EventItemDetailRenderable;
  meta: EventTypeMeta;
} | null;

export interface EventSearchResultsStore {
  resultsStatus: ResultsStatus;
  tableResultsStatus: ResultsStatus;
  loadingMore: boolean;
  moreToLoad: boolean;
  eventItems: EventItem[];
  tableColVisibility: EventItemTableColVisibility;
  showTableColSelector: boolean;
  tableHeader: string[];
  tableRows: TableRowKeyed[];
  totalRowCount: number;
  pageIndex: number;
  eventChartResponse: EventSearchCuration;
  chartHeading: string;
  chartTicsAndBars: TicsAndBarHeights<EventSummary>;
  chartXAxisLabels: string[];
  hoveredEventSummary: NullableEventSummary;
  eventDetailStatus: ResultsStatus;
  eventDetail: NullableStoreEventDetail;
  selectedEventSummary: NullableEventSummary;
}

export interface EventSearchFilterPanelStore {
  monthOptions: SelectOption[];
  monthSelected: string;
  portalOptions: SelectOption[];
  portalsSelected: string[];
  eventOptions: SelectOption[];
  eventTypePortalItems: EventTypePortalItem[];
  eventsSelected: string[];
  typeOptions: IdTypeSelectOption[];
  typeSelected: IdType;
  typeValidationMsg: string;
  value: string;
  valueValidationMsg: string;
  searchBtnEnabled: boolean;
  loading: boolean;
}

export interface EventCurationRequest {
  startDate: string;
  endDate: string;
  portals: EventTypePortal[];
  accountId: string;
  ipAddress: string;
}

export interface EventSearchRequest extends EventCurationRequest {
  pageNumber: number;
  pageSize: number;
}

export interface EventItem {
  externalTraceId: string;
  clientId: string;
  eventType: EventType;
  accountSourceType: string;
  accountId: string;
  eventDateTime: string;
  ipAddress: string;
  askId: string;
  dataSource: string;
  internalTraceId: string;
  sourceReportVersion: string;
  ipState: string;
  ipCountry: string;
  ipCountryCode: string;
}
export type EventItemTableColVisibility = {
  [K in keyof EventItem]: boolean;
};

export type EventItemDetail = __EventItemDetail;

export type EventItemLabels = {
  [K in keyof EventItemDetail]: string;
};

export interface EventSearchResponse extends PagedContent {
  content: EventItem[];
}

export interface EventSearchCuration {
  totalEvents: number;
  counts: EventCount[];
}

export interface EventFilters {
  portals: SelectOption[];
  eventTypePortalItems: EventTypePortalItem[];
  eventTypeSelectItems: SelectOption[];
}

export interface FilterPanelState {
  month: string;
  portals: string[];
  events: string[];
  idType: IdType;
  value: string;
}

export interface SearchProps {
  state: FilterPanelState;
  eventTypePortalItems: EventTypePortalItem[];
  pageIndex: number;
}

export interface TableCellValue {
  value: string;
  key: string;
}

export interface TableRowKeyed {
  rowKey: string;
  eventItem: EventItem;
  cellValues: TableCellValue[];
}

export interface EventTypeMeta {
  label: string;
  color: string;
}

export interface EventSummaryItem {
  eventTypeMeta: EventTypeMeta;
  count: number;
  countFormatted: string;
}
export interface EventSummary {
  dateFormatted: string;
  dateIso: string;
  events: EventSummaryItem[];
}

export type NullableEventSummary = EventSummary | null;

export interface EventItemDetailRenderable {
  populated: SelectOption[];
  blank: SelectOption[];
  all: SelectOption[];
}
