/* eslint-disable arrow-body-style */

import { createProxyDate, REALLY_OLD_DATE_UTC } from "@src/common/util/proxyDate";

const REALLY_OLD_DATE_UTC_MILLI_SEC = REALLY_OLD_DATE_UTC.getTime()

const dateCache = new Map<string, Date>();

/**
 * All dates stored in the backend are in ISO format 'YYYY/MM/DD'.
 * Unfortunately, when they come across the wire the JSON for these dates are formatted in US date 'MM/DD/YYYY', that's the contract.
 * However, the backend may also return <code>null</code> to signify absence of a date.
 *
 * We wrap all Dates in a ProxyDate and if no date is supplied then we default to an ancient date - EPOCH.
 * @param dateStr Expected US formatted date string or empty or null string
 */
export const toDate = (dateStr: string | null): any => dateStr ? createProxyDate(dateStr) : REALLY_OLD_DATE_UTC;

/**
 * Simple cache to reuse as many ProxyDate objects as possible
 * @param dateStr a US format date string
 */
export const getCachedDate = (dateStr: string): Date => {
  if (!dateCache.has(dateStr)) {
    dateCache.set(dateStr, toDate(dateStr));
  }
  return dateCache.get(dateStr)!;
};

/**
 * Tests if the date passed in is actually our EPOCH data
 * @param date the US formatted date string
 */
export const isEpochDate = (date: Date): boolean => date.getTime() === REALLY_OLD_DATE_UTC_MILLI_SEC;
