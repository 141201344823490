/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatPortal, getFormattedTimeDate } from '@src/common/util/formatter';
import { EventItemDetail, EventItemDetailRenderable } from '../types';
import { eventItemDetailLabels } from '../EventItemDetailLabels';

export function getRenderableEventItem(
  event: EventItemDetail
): EventItemDetailRenderable {
  const all = getAll(event);

  return {
    all,
    populated: all.filter((e) => !!e.value),
    blank: all.filter((e) => !e.value),
  };
}

function getAll(e: EventItemDetail) {
  const l = eventItemDetailLabels;
  return [
    {
      label: l.eventDateTime,
      value: e.eventDateTime
        ? getFormattedTimeDate(e.eventDateTime)
        : e.eventDateTime,
    },
    {
      label: l.ipAddress,
      value: e.ipAddress,
    },
    {
      label: l.clientId,
      value: formatPortal(e.clientId),
    },
    {
      label: l.accountSourceType,
      value: e.accountSourceType,
    },
    {
      label: l.accountId,
      value: e.accountId,
    },
    {
      label: l.externalTraceId,
      value: e.externalTraceId,
    },
    {
      label: l.askId,
      value: e.askId,
    },
    {
      label: l.dataSource,
      value: e.dataSource,
    },
    {
      label: l.firstName,
      value: e.firstName,
    },
    {
      label: l.middleName,
      value: e.middleName,
    },
    {
      label: l.lastName,
      value: e.lastName,
    },
    {
      label: l.ssn,
      value: e.ssn,
    },
    {
      label: l.orgName,
      value: e.orgName,
    },
    {
      label: l.streetAddress,
      value: e.streetAddress,
    },
    {
      label: l.city,
      value: e.city,
    },
    {
      label: l.state,
      value: e.state,
    },
    {
      label: l.zip,
      value: e.zip,
    },
    {
      label: l.internalTraceId,
      value: e.internalTraceId,
    },
    {
      label: l.deviceIds,
      value: e.deviceIds.join(', '),
    },
    {
      label: l.urls,
      value: e.urls.join(', '),
    },
    {
      label: l.userBrowser,
      value: e.userBrowser,
    },
    {
      label: l.userOss,
      value: e.userOss.join(', '),
    },
    {
      label: l.targetPortal,
      value: e.targetPortal,
    },
    {
      label: l.enrlTelNumber,
      value: e.enrlTelNumber,
    },
    {
      label: l.userTelNumber,
      value: e.userTelNumber,
    },
    {
      label: l.phoneNumber1,
      value: e.phoneNumber1,
    },
    {
      label: l.email,
      value: e.email,
    },
    {
      label: l.sourceReportVersion,
      value: e.sourceReportVersion,
    },
    {
      label: l.ipState,
      value: e.ipState,
    },
    {
      label: l.ipCountry,
      value: e.ipCountry,
    },
    {
      label: l.ipCountryCode,
      value: e.ipCountryCode,
    },
    {
      label: l.ipIsp,
      value: e.ipIsp,
    },
    {
      label: l.bankChangeType,
      value: e.bankChangeType,
    },
    {
      label: l.username,
      value: e.username,
    },
    {
      label: l.statusCode,
      value: e.statusCode,
    },
    {
      label: l.routingNumber,
      value: e.routingNumber,
    },
    {
      label: l.accountNumber,
      value: e.accountNumber,
    },
    {
      label: l.marketType,
      value: e.marketType,
    },
    {
      label: l.paymentType,
      value: e.paymentType,
    },
    {
      label: l.providerType,
      value: e.providerType,
    },
    {
      label: l.accountStatus,
      value: e.accountStatus,
    },
    {
      label: l.accountOwnership,
      value: e.accountOwnership,
    },
    {
      label: l.priorEventValue,
      value: e.priorEventValue,
    },
    {
      label: l.pastEventValue,
      value: e.pastEventValue,
    },
    {
      label: l.createdDateTime,
      value: e.createdDateTime
        ? getFormattedTimeDate(e.createdDateTime)
        : e.createdDateTime,
    },
  ];
}
