export interface SelectOption {
  value: string;
  label: string;
}

export interface PagedContent {
  number: number;
  size: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
  first: boolean;
  last: boolean;
  empty: boolean;
}

export const getDefaultPagedContent = (): PagedContent => {
  return {
    number: 0,
    size: 0,
    numberOfElements: 0,
    totalElements: 0,
    totalPages: 0,
    first: true,
    last: true,
    empty: true,
  };
};
