/* eslint-disable arrow-body-style */
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import React from 'react';
import { constants } from '@src/routes/provider/common/constants';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const EventsSelect = () => {
  const options = useEventSearchFilterPanelStore((state) => state.eventOptions);
  const value = useEventSearchFilterPanelStore((state) => state.eventsSelected);
  const loading = useEventSearchFilterPanelStore((state) => state.loading);
  const placeholder = loading ? constants.LOADING : 'Select Events';
  const renderValue = loading ? [''] : value;

  const { setEventValue } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-select-events"
      data-value={value}
      data-options={JSON.stringify(options)}
      data-loading={loading}
    >
      <SelectInputMulti
        label="Events"
        placeholder={placeholder}
        value={renderValue}
        onChange={setEventValue}
        options={options}
        selectAll
        hideChips
        isLoading={loading}
      />
    </div>
  );
};
