/* eslint-disable arrow-body-style */
import { SelectInput } from '@abyss/web/ui/SelectInput';
import React from 'react';
import { constants } from '@src/routes/provider/common/constants';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const MonthSelect = () => {
  const options = useEventSearchFilterPanelStore((state) => state.monthOptions);
  const value = useEventSearchFilterPanelStore((state) => state.monthSelected);
  const loading = useEventSearchFilterPanelStore((state) => state.loading);
  const placeholder = loading ? constants.LOADING : 'Please select a month';
  const renderValue = loading ? '' : value;

  const { setMonthValue } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-select-month"
      data-value={value}
      data-options={JSON.stringify(options)}
      data-loading={loading}
    >
      <SelectInput
        label="Month"
        placeholder={placeholder}
        value={renderValue}
        onChange={setMonthValue}
        options={options}
        isLoading={loading}
      />
    </div>
  );
};
