/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useRef } from 'react';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import {
  eventSearchResultsApi,
  useEventSearchResultsStore,
} from '../../../mothership/eventSearchResults.state';

export const LoadTrigger = () => {
  const loading = useEventSearchResultsStore((state) => state.loadingMore);
  const moreToLoad = useEventSearchResultsStore((state) => state.moreToLoad);

  const ref = useRef(document.createElement('div'));
  useEffect(() => {
    eventSearchResultsApi.setInfiniteScrollElement(ref.current);
  }, [ref, ref.current]);

  if (loading) {
    return (
      <div
        data-cy="eventSearchLoadingMore"
        data-testid="eventSearchLoadingMore"
        key="loading-more"
        className="eventSearchLoadingMore"
      >
        <div data-testid="loading">
          <LoadingSpinner
            size="$lg"
            color="$secondary3"
            ariaLoadingLabel="Event Search, loading more results, please wait"
            isLoading
          />
        </div>
        <div className="eventSearchLoadingMoreLabel">Please wait</div>
      </div>
    );
  }

  if (moreToLoad) {
    return <div data-testid="triggering" key="triggering" ref={ref}></div>;
  }

  return <div data-testid="not-triggering"></div>;
};
