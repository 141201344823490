import React, { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  id: string;
  chartHeightPx: number;
}

export const ChartContainer: FC<Props> = ({ id, chartHeightPx, children }) => {
  return (
    <div
      data-testid={id}
      data-cy={id}
      className="dfedsChart"
      style={{ height: `${chartHeightPx}px` }}
    >
      {children}
    </div>
  );
};
