/* eslint-disable react/self-closing-comp */
import React, { FC } from 'react';

interface Props {
  color: string;
}

export const EventColor: FC<Props> = ({ color }) => {
  return (
    <span
      className="eventTypeColorIndicator"
      style={{ backgroundColor: color }}
    ></span>
  );
};
