/* eslint-disable arrow-body-style */
import React from 'react';
import { useEventSearchResultsStore } from '../../../mothership/eventSearchResults.state';
import { ResultsStatus } from '../../../types';
import { ResultsError } from '../ResultsError';
import { ResultsSearching } from '../ResultsSearching';
import { ColumnSelectorDrawer } from './ColumnSelectorDrawer';
import { EventItemDetailsDrawer } from './EventItemDetailDrawer';
import { LoadTrigger } from './LoadTrigger';
import { ResultsTableBody } from './ResultsTableBody';
import { ResultsTableControls } from './ResultsTableControls';
import { ResultsTableHead } from './ResultsTableHead';

export const ResultsTable = () => {
  const tableResultsStatus = useEventSearchResultsStore(
    (state) => state.tableResultsStatus
  );

  if (tableResultsStatus === ResultsStatus.error) {
    return (
      <div className="eventSearchTableResults">
        <ResultsError />
      </div>
    );
  }

  if (tableResultsStatus === ResultsStatus.searching) {
    return (
      <div className="eventSearchTableResults">
        <ResultsSearching />
      </div>
    );
  }

  return (
    <React.Fragment>
      <EventItemDetailsDrawer />
      <ColumnSelectorDrawer />
      <ResultsTableControls />
      <div
        data-testid="event-search-table"
        data-cy="event-search-table"
        className="defeds-table-container"
      >
        <table className="defeds-table">
          <ResultsTableHead />
          <ResultsTableBody />
        </table>
      </div>
      <LoadTrigger />
    </React.Fragment>
  );
};
