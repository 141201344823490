export enum BarSelectStatus {
  barSelected = 'barSelected',
  barDeselected = 'barDeselected',
  noBarSelected = 'noBarSelected',
}
export interface Bar<Payload> {
  height: number;
  value: number;
  valueFormatted: string;
  key: string;
  payload: Payload;
}

export interface Tic {
  label: string;
  height: number;
  value: number;
  isInteger: boolean;
}

export interface TicsAndBarHeights<Payload> {
  tics: Tic[];
  bars: Bar<Payload>[];
}
