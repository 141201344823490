/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { AjaxResponse } from '@src/common/ajax/types';
import { ajax } from '@src/common/ajax';
import { SelectOption } from '@src/common/types';
import { ApiUrl } from '@src/common/ajax/apiUrl';
import { EventFilters, EventTypePortalItem } from '../types';

export interface EventTypeInfo {
  eventTypePortalItems: EventTypePortalItem[];
  eventTypeSelectItems: SelectOption[];
}

export async function fetchFilters(): Promise<AjaxResponse<EventFilters>> {
  const [portalsResp, eventTypesResp] = await Promise.all([
    fetchPortals(),
    fetchEventTypes(),
  ]);
  const status =
    portalsResp.status > eventTypesResp.status
      ? portalsResp.status
      : eventTypesResp.status;
  const success = portalsResp.success && eventTypesResp.success;

  return {
    payload: {
      ...eventTypesResp.payload,
      portals: portalsResp.payload,
    },
    status,
    success,
  };
}

export async function fetchPortals(): Promise<AjaxResponse<SelectOption[]>> {
  return fetchOptions(ApiUrl.portals);
}

export async function fetchEventTypes(): Promise<AjaxResponse<EventTypeInfo>> {
  const { payload, ...rest } = await ajax<{ content: EventTypePortalItem[] }>(
    {
      content: [],
    },
    ApiUrl.eventTypes,
    {
      method: 'get',
    }
  );

  const eventTypePortalItems = sanitizeEventTypesResponse(payload.content);

  const eventTypeSelectItems = eventTypePortalItems
    .map((item) => ({
      label: item.name,
      value: item.code,
    }))
    .filter(beUnique);

  return {
    payload: {
      eventTypePortalItems,
      eventTypeSelectItems,
    },
    ...rest,
  };
}

async function fetchOptions(
  url: string
): Promise<AjaxResponse<SelectOption[]>> {
  const { payload: payloadTentative, ...rest } = await ajax(
    {
      content: [],
    },
    url,
    {
      method: 'get',
    }
  );
  const payload = Array.isArray(payloadTentative.content)
    ? sanitizeSelectOptions(payloadTentative.content)
    : [];
  return {
    payload,
    ...rest,
  };
}

function sanitizeSelectOptions(items: SelectOption[]): SelectOption[] {
  return items
    .filter((item) => !!item)
    .map((item) => {
      return {
        label: item.label ?? '',
        value: item.value ?? '',
      };
    });
}

function sanitizeEventTypesResponse(
  content: EventTypePortalItem[]
): EventTypePortalItem[] {
  let eventTypePortalItems = Array.isArray(content) ? [...content] : [];
  eventTypePortalItems = eventTypePortalItems.filter((item) => !!item);
  return eventTypePortalItems.map((item) => {
    return {
      code: item.code ?? '',
      name: item.name ?? '',
      portalCode: item.portalCode ?? '',
    };
  });
}

function beUnique(
  item: SelectOption,
  index: number,
  self: SelectOption[]
): boolean {
  return index === self.findIndex((t) => t.value === item.value);
}
