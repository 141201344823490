/* eslint-disable arrow-body-style */

import React from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const ValueField = () => {
  const value = useEventSearchFilterPanelStore((state) => state.value);
  const validationMsg = useEventSearchFilterPanelStore(
    (state) => state.valueValidationMsg
  );

  const { setValue, submitKbd } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-value-field"
      data-value={value}
      data-valid={!validationMsg}
    >
      <TextInput
        label="ID Value"
        placeholder="Enter Value"
        value={value}
        onChange={setValue}
        errorMessage={validationMsg}
        onKeyDown={submitKbd}
      />
    </div>
  );
};
