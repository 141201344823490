import React from 'react';

import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';

export const ResultsSearching = () => {
  return (
    <div
      className="resultsSearching"
      data-testid="eventSearchResultsSearching"
      data-cy="eventSearchResultsSearching"
    >
      <LoadingSpinner
        size="$lg"
        color="$secondary3"
        ariaLoadingLabel="Event Search, Searching data, Waiting for data"
        isLoading
      />
      <div className="labelResultsSearching" data-cy="labelResultsSearching">
        Please wait
      </div>
    </div>
  );
};
