/* eslint-disable arrow-body-style */
import React from 'react';
import { useEventSearchResultsStore } from '../../../mothership/eventSearchResults.state';

export const EventItemDetailView = () => {
  const eventDetail = useEventSearchResultsStore((state) => state.eventDetail);
  if (!eventDetail) {
    return null;
  }
  const {
    eventRenderable: { all },
  } = eventDetail;

  return (
    <div
      className="eventItemDetailsContainer"
      data-testid="eventSearchResultsDetail"
      data-cy="eventSearchResultsDetail"
    >
      <table className="defeds-table">
        <colgroup>
          <col style={{ width: '1%' }} />
          <col style={{ width: '99%' }} />
        </colgroup>
        <tbody>
          {all.map(({ label, value }) => (
            <tr key={label}>
              <th>{label}</th>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
