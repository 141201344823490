import React from 'react';

import { IconMaterial } from '@abyss/web/ui/IconMaterial';

export const ResultsPending = () => {
  return (
    <div
      className="resultsPending"
      data-testid="eventSearchResultsPending"
      data-cy="eventSearchResultsPending"
    >
      <div className="iconLabel">
        <div className="iconLabelIcon">
          <IconMaterial
            icon="data_exploration"
            variant="outlined"
            size={100}
            color="#ccc"
          />
        </div>
        <div className="iconLabelLabel">Search for results</div>
      </div>
    </div>
  );
};
