/* eslint-disable no-param-reassign */
import React, { FC } from 'react';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { eventSearchResultsApi } from '../../../mothership/eventSearchResults.state';

interface Props {
  setter: (checked: boolean) => void;
  label: string;
  checked: boolean;
}

export const ColumnSelectionToggle: FC<Props> = ({
  setter,
  label,
  checked,
}) => {
  const testId = `eventSearchColToggle${label}`;
  return (
    <label
      className="eventSearchColToggle"
      data-testid={testId}
      data-cy={testId}
    >
      <div className="eventSearchColumnSelectorIcon">
        {checked && (
          <IconSymbol
            icon="visibility"
            variant="outlined"
            color="$gray5"
            size="18px"
          />
        )}
        {!checked && (
          <IconSymbol
            icon="visibility_off"
            variant="outlined"
            color="$gray5"
            size="18px"
          />
        )}
      </div>
      <div className="eventSearchColumnSelectorIcon">{label}</div>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setter(e.target.checked);
          eventSearchResultsApi.commitColumnSelector();
        }}
      />
    </label>
  );
};
