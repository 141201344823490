/* eslint-disable arrow-body-style */
import { Bar } from '@src/common/charts/types';
import React, { FC, useEffect, useState } from 'react';
import {
  eventSearchResultsApi,
  useEventSearchResultsStore,
} from '../../mothership/eventSearchResults.state';
import { EventSummary } from '../../types';
import { getBarSelectedStatus } from './getBarSelectedStatus';

interface Props {
  bar: Bar<EventSummary>;
  xAxisLabel: string;
}

export const ResultsChartBar: FC<Props> = ({ bar, xAxisLabel }) => {
  const [height, setHeight] = useState('0px');

  const selectedEventSummary = useEventSearchResultsStore(
    (state) => state.selectedEventSummary
  );
  const { clearHoveredEventsSummary, setHoveredEventsSummary, onMouseMove } =
    eventSearchResultsApi.chartTooltip;

  const barSelectStatus = getBarSelectedStatus(
    selectedEventSummary,
    bar.payload.dateIso
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setHeight(`${bar.height}px`);
    });
  }, [bar]);

  return (
    <React.Fragment>
      <div
        className={`dfedsBar ${barSelectStatus}`}
        style={{ height }}
        data-value={bar.value}
        data-value-formatted={bar.valueFormatted}
        role="button"
        onKeyDown={(e) => eventSearchResultsApi.searchEventsKbd(e, bar.payload)}
        onClick={() => eventSearchResultsApi.searchEvents(bar.payload)}
        onMouseEnter={() => setHoveredEventsSummary(bar)}
        onMouseLeave={clearHoveredEventsSummary}
        onMouseMove={onMouseMove}
        tabIndex={bar.value === 0 ? -1 : 0}
      >
        <div className="xAxisLabel">{xAxisLabel}</div>
      </div>
    </React.Fragment>
  );
};
