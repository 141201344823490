/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */
import React from 'react';
import { useEventSearchFilterPanelStore } from '../../mothership/eventSearchFilterPanel.state';

/*
  Only re-render this component when loading changes
*/
export const EvtSearchLoadingAttr = () => {
  const loading = useEventSearchFilterPanelStore((state) => state.loading);
  return <div data-cy="eventSearchFilterPanel" data-loading={loading}></div>;
};
