/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { dayjs } from '@abyss/web/tools/dayjs';
import {
  EventCurationRequest,
  EventSearchRequest,
  EventType,
  EventTypePortal,
  EventTypePortalItem,
  IdType,
  Portal,
  SearchProps,
} from './types';

type Props = SearchProps & { pageSize: number; isoDateOverride: string | null };

export function getEventCurationRequest({
  startDate,
  endDate,
  portals,
  accountId,
  ipAddress,
}: EventSearchRequest): EventCurationRequest {
  return {
    startDate,
    endDate,
    portals,
    ipAddress,
    accountId,
  };
}

export function getEventSearchRequest({
  state: { events, idType, month, portals, value },
  eventTypePortalItems,
  pageIndex,
  pageSize,
  isoDateOverride,
}: Props): EventSearchRequest {
  const resp: EventSearchRequest = {
    ...getMonthStartEnd(month),
    ...getValue(idType, value),
    pageNumber: pageIndex,
    pageSize,
    portals: getRequestEventTypes(eventTypePortalItems, portals, events),
  };
  if (isoDateOverride) {
    resp.startDate = isoDateOverride;
    resp.endDate = isoDateOverride;
  }
  return resp;

  function getMonthStartEnd(isoDate: string) {
    const startDate = dayjs(isoDate).startOf('month').format('YYYY-MM-DD');
    const endDate = dayjs(isoDate).endOf('month').format('YYYY-MM-DD');
    return { startDate, endDate };
  }

  function getValue(_idType: IdType, _value: string) {
    if (_idType === IdType.accountId) {
      return {
        accountId: _value.trim(),
        ipAddress: '',
      };
    }

    return {
      accountId: '',
      ipAddress: _value.trim(),
    };
  }

  function getRequestEventTypes(
    eventTypePortals: EventTypePortalItem[],
    selectedPortals: string[],
    selectedEvents: string[]
  ): EventTypePortal[] {
    const eventItems = eventTypePortals.filter((item) => {
      return (
        selectedPortals.includes(item.portalCode) &&
        selectedEvents.includes(item.code)
      );
    });

    const portalRequestItems: EventTypePortal[] = [];
    const map = new Map<Portal, EventType[]>();
    eventItems.forEach((item) => map.set(item.portalCode, []));
    eventItems.forEach((item) => {
      map.set(item.portalCode, [...map.get(item.portalCode)!, item.code]);
    });
    map.forEach((eventTypes, portal) => {
      portalRequestItems.push({ portal, eventTypes });
    });
    return portalRequestItems;
  }
}
