/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */
import React from 'react';
import { ChartContainer } from '@src/common/charts/bar-chart/ChartContainer';

import { BarsContainer } from '@src/common/charts/bar-chart/BarsContainer';
import { YAxis } from '@src/common/charts/bar-chart/YAxis';
import { ChartHeading } from '@src/common/charts/bar-chart/ChartHeading';
import {
  CHART_HEIGHT,
  useEventSearchResultsStore,
} from '../../mothership/eventSearchResults.state';
import { ResultsChartBar } from './ResultsChartBar';

export const ResultsChart = () => {
  const { bars, tics } = useEventSearchResultsStore(
    (state) => state.chartTicsAndBars
  );
  const chartHeading = useEventSearchResultsStore(
    (state) => state.chartHeading
  );
  const chartXAxisLabels = useEventSearchResultsStore(
    (state) => state.chartXAxisLabels
  );

  return (
    <div style={{ margin: '30px 0px 80px 0px' }}>
      <ChartHeading heading={chartHeading} />
      <ChartContainer id="eventSearchResultsChart" chartHeightPx={CHART_HEIGHT}>
        <YAxis tics={tics} heading="Events" hideNonWholeNumbers />
        <BarsContainer>
          {bars.map((bar, i) => (
            <ResultsChartBar
              key={bar.key}
              bar={bar}
              xAxisLabel={chartXAxisLabels[i]}
            />
          ))}
        </BarsContainer>
      </ChartContainer>
    </div>
  );
};
