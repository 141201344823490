import { EventItemLabels } from './types';

export const eventItemDetailLabels: EventItemLabels = {
  externalTraceId: 'External Trace ID',
  askId: 'Ask ID',
  dataSource: 'Data Source',
  eventType: 'Event',
  eventDateTime: 'Time',
  accountSourceType: 'Account Type',
  accountId: 'Account Value',
  firstName: 'First name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  ssn: 'SSN',
  orgName: 'Org Name',
  streetAddress: 'Street Address',
  city: 'City',
  state: 'State',
  zip: 'ZIP',
  internalTraceId: 'UUID',
  ipAddress: 'IP Address',
  deviceIds: 'Device ID',
  urls: 'URL',
  userBrowser: 'Browser',
  userOss: 'OS',
  targetPortal: 'Target Portal',
  enrlTelNumber: 'Enrollment Phone',
  userTelNumber: 'User Phone',
  phoneNumber1: 'Phone Number',
  email: 'Email',
  clientId: 'Portal',
  sourceReportVersion: 'Source Report Version',
  ipState: 'IP State',
  ipCountry: 'IP Country',
  ipCountryCode: 'IP Country Code',
  ipIsp: 'IP ISP',
  bankChangeType: 'Bank Change Type',
  username: 'Username',
  statusCode: 'Enrollment Status',
  routingNumber: 'Bank Routing Number',
  accountNumber: 'Bank Account Number',
  marketType: 'Market Type',
  paymentType: 'Payment Type',
  providerType: 'Provider Type',
  accountStatus: 'EWS Status',
  accountOwnership: 'EWS Account Ownership',
  priorEventValue: 'Prior Event Value',
  pastEventValue: 'Post Event Value',
  createdDateTime: 'Created Date Time',
};
