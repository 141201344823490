import React from 'react';

export const ResultsError = () => {
  return (
    <div
      className="resultsError"
      data-testid="eventSearchResultsError"
      data-cy="eventSearchResultsError"
    >
      <div className="labelResultsError">
        An error occurred retrieving your search results, please try again.
      </div>
    </div>
  );
};
