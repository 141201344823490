/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable arrow-body-style */
import { getUid } from '@src/common/util/getUid';
import { formatPortal, getFormattedTimeDate } from '@src/common/util/formatter';
import {
  EventItem,
  EventItemTableColVisibility,
  EventType,
  TableCellValue,
  TableRowKeyed,
} from './types';
import { eventItemDetailLabels } from './EventItemDetailLabels';

interface RowProps {
  eventItems: EventItem[];
  tableColVisibility: EventItemTableColVisibility;
}

export function getTableRows({
  eventItems,
  tableColVisibility,
}: RowProps): TableRowKeyed[] {
  const tableRowKeyed: TableRowKeyed[] = eventItems.map((eventItem) => {
    const row: TableRowKeyed = {
      eventItem,
      cellValues: getCellValues(eventItem, tableColVisibility),
      rowKey: getUid(),
    };
    return row;
  });
  return tableRowKeyed;

  function getCellValues(
    eventItem: EventItem,
    visibility: EventItemTableColVisibility
  ): TableCellValue[] {
    const {
      eventType,
      eventDateTime,
      ipAddress,
      clientId,
      accountSourceType,
      accountId,
      externalTraceId,
      askId,
      dataSource,
      internalTraceId,
      sourceReportVersion,
      ipState,
      ipCountry,
      ipCountryCode,
    } = eventItem;
    const cellValues: TableCellValue[] = [
      { value: eventType, visible: visibility.eventType },
      {
        value: getFormattedTimeDate(eventDateTime),
        visible: visibility.eventDateTime,
      },
      { value: ipAddress, visible: visibility.ipAddress },
      { value: formatPortal(clientId), visible: visibility.clientId },
      {
        value: accountSourceType,
        visible: visibility.accountSourceType,
      },
      { value: accountId, visible: visibility.accountId },
      {
        value: externalTraceId,
        visible: visibility.externalTraceId,
      },
      { value: askId, visible: visibility.askId },
      { value: dataSource, visible: visibility.dataSource },
      { value: internalTraceId, visible: visibility.internalTraceId },
      {
        value: sourceReportVersion,
        visible: visibility.sourceReportVersion,
      },
      { value: ipState, visible: visibility.ipState },
      { value: ipCountry, visible: visibility.ipCountry },
      { value: ipCountryCode, visible: visibility.ipCountryCode },
    ]
      .filter(({ visible }) => visible)
      .map(({ value }) => ({ value, key: getUid() }));
    return cellValues;
  }
}

export function getTableHeaders(
  visibility: EventItemTableColVisibility
): string[] {
  const {
    eventType,
    eventDateTime,
    ipAddress,
    clientId,
    accountSourceType,
    accountId,
    externalTraceId,
    askId,
    dataSource,
    internalTraceId,
    sourceReportVersion,
    ipState,
    ipCountry,
    ipCountryCode,
  } = getDefaultHeaders();

  return [
    { value: eventType, visible: visibility.eventType },
    { value: eventDateTime, visible: visibility.eventDateTime },
    { value: ipAddress, visible: visibility.ipAddress },
    { value: clientId, visible: visibility.clientId },
    { value: accountSourceType, visible: visibility.accountSourceType },
    { value: accountId, visible: visibility.accountId },
    { value: externalTraceId, visible: visibility.externalTraceId },
    { value: askId, visible: visibility.askId },
    { value: dataSource, visible: visibility.dataSource },
    { value: internalTraceId, visible: visibility.internalTraceId },
    { value: sourceReportVersion, visible: visibility.sourceReportVersion },
    { value: ipState, visible: visibility.ipState },
    { value: ipCountry, visible: visibility.ipCountry },
    { value: ipCountryCode, visible: visibility.ipCountryCode },
  ]
    .filter(({ visible }) => visible)
    .map(({ value }) => value);
}

export const getDefaultHeaders = (): EventItem => ({
  externalTraceId: eventItemDetailLabels.externalTraceId,
  clientId: eventItemDetailLabels.clientId,
  eventType: eventItemDetailLabels.eventType as EventType,
  accountSourceType: eventItemDetailLabels.accountSourceType,
  accountId: eventItemDetailLabels.accountId,
  eventDateTime: eventItemDetailLabels.eventDateTime,
  ipAddress: eventItemDetailLabels.ipAddress,
  askId: eventItemDetailLabels.askId,
  dataSource: eventItemDetailLabels.dataSource,
  internalTraceId: eventItemDetailLabels.internalTraceId,
  sourceReportVersion: eventItemDetailLabels.sourceReportVersion,
  ipState: eventItemDetailLabels.ipState,
  ipCountry: eventItemDetailLabels.ipCountry,
  ipCountryCode: eventItemDetailLabels.ipCountryCode,
});
