/* eslint-disable arrow-body-style */
import React from 'react';
import { useEventSearchResultsStore } from '@src/routes/events/search/mothership/eventSearchResults.state';
import { ResultsStatus } from '../../../types';
import { ResultsSearching } from '../ResultsSearching';
import { ResultsError } from '../ResultsError';
import { EventItemDetailView } from './EventItemDetailView';

export const EventItemDetailsDrawerContents = () => {
  const status = useEventSearchResultsStore((state) => state.eventDetailStatus);

  if (status === ResultsStatus.searching) {
    return <ResultsSearching />;
  }

  if (status === ResultsStatus.error) {
    return <ResultsError />;
  }

  if (status === ResultsStatus.results) {
    return <EventItemDetailView />;
  }

  return null;
};
