/* eslint-disable arrow-body-style */
import { SelectInput } from '@abyss/web/ui/SelectInput';
import React from 'react';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const TypeSelect = () => {
  const options = useEventSearchFilterPanelStore((state) => state.typeOptions);
  const value = useEventSearchFilterPanelStore((state) => state.typeSelected);
  const validationMsg = useEventSearchFilterPanelStore(
    (state) => state.typeValidationMsg
  );

  const { setTypeValue } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-select-type"
      data-value={value}
      data-options={JSON.stringify(options)}
      data-valid={!validationMsg}
    >
      <SelectInput
        label="ID Type"
        placeholder="Select an ID Type"
        value={value}
        onChange={setTypeValue}
        options={options}
        errorMessage={validationMsg}
      />
    </div>
  );
};
