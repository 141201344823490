/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { isClient } from '@src/common/util/isClient';

export const infiniteScrollManager = isClient() ? infiniteScrollManagerFn() : infiniteScrollManagerFnServer();

function infiniteScrollManagerFn() {
  let triggerElem = document.createElement('div');
  let triggeredCallback = async () => Promise<any>;
  const infiniteLoadObserver = new IntersectionObserver(observer);

  return {
    onTriggered,
    setTriggerElement,
  };

  function observer(entries: IntersectionObserverEntry[]) {
    if (isTriggered()) {
      triggeredCallback();
    }

    function isTriggered() {
      const trigger = entries.find((entry) => entry.target === triggerElem);
      return trigger && trigger.isIntersecting;
    }
  }

  function onTriggered(callback: () => Promise<any>) {
    triggeredCallback = callback;
  }

  function setTriggerElement(elem: HTMLDivElement) {
    if(!elem || !Object.keys(elem).length) {
      return;
    }

    infiniteLoadObserver.unobserve(triggerElem);
    triggerElem = elem;
    infiniteLoadObserver.observe(triggerElem);
  }
}

function infiniteScrollManagerFnServer() {
  return {
    setTriggerElement: () => {},
    onTriggered: () => {}
  };
}
