/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { ajax } from '@src/common/ajax';
import { ApiUrl } from '@src/common/ajax/apiUrl';
import { AjaxResponse } from '@src/common/ajax/types';
import {
  EventCount,
  EventCountItem,
  EventCurationRequest,
  EventSearchCuration,
  EventType,
} from '../types';
import { getEventTypeMeta } from '../mothership/eventTypeMap';

export async function fetchEventsCuration(
  props: EventCurationRequest
): Promise<AjaxResponse<EventSearchCuration>> {
  const fallbackResponse: EventSearchCuration = {
    totalEvents: 0,
    counts: [],
  };

  const body = JSON.stringify(props);

  const { payload, ...rest } = await ajax(
    fallbackResponse,
    ApiUrl.eventCuration,
    { body }
  );

  return {
    payload: sanitize(payload),
    ...rest,
  };
}

function sanitize(resp: EventSearchCuration): EventSearchCuration {
  let sanitized: EventSearchCuration = {
    counts: [],
    totalEvents: 0,
  };
  sanitized = { ...sanitized, ...resp };
  sanitized = {
    counts: sanitized.counts ?? [],
    totalEvents: sanitized.totalEvents ?? 0,
  };
  sanitized.counts = sanitized.counts.filter((elem) => !!elem);
  sanitized.counts = sanitized.counts
    .map(sanitizeElement)
    .sort((a, b) => (a.date > b.date ? 1 : -1));
  return sanitized;
}

function sanitizeElement(item: EventCount): EventCount {
  const defaultItem: EventCount = {
    date: '',
    events: [],
    totalEvents: 0,
  };
  const sanitized = { ...defaultItem, ...item };
  sanitized.events = sanitized.events.filter((elem) => !!elem);
  sanitized.events = sanitized.events.map((elem) => ({
    count: elem.count ?? 0,
    eventType: elem.eventType ?? EventType.unknown,
  }));
  sanitized.events = getSortedEvents(sanitized.events);
  return sanitized;
}

function getSortedEvents(eventItems: EventCountItem[]): EventCountItem[] {
  return eventItems.slice().sort((a, b) => {
    const aLabel = getEventTypeMeta(a.eventType).label;
    const bLabel = getEventTypeMeta(b.eventType).label;
    return aLabel > bLabel ? 1 : -1;
  });
}
