import React, { FC } from 'react';

interface Props {
  heading: string;
}

export const ChartHeading: FC<Props> = ({ heading }) => {
  return (
    <div role="heading" aria-level={2} className="dfedsChartHeading">
      {heading}
    </div>
  );
};
