/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { EventCount, EventSummary } from '@src/routes/events/search/types';
import { getEventTypeMeta } from '@src/routes/events/search/mothership/eventTypeMap';
import { getUid } from '../util/getUid';
import { generateYTics } from './generateYAxisTics';
import { Bar, Tic, TicsAndBarHeights } from './types';
import { getFormattedMonthDay } from '../util/formatter';

interface Accum {
  currentVal: number;
  values: number[];
}
interface Props {
  chartHeight: number;
  curation: EventCount[];
  maxValueAddPercent: number;
}

export function generateTicsAndHeights({
  chartHeight,
  curation,
  maxValueAddPercent,
}: Props): TicsAndBarHeights<EventSummary> {
  const values = curation.map((c) => c.totalEvents);
  const maxValue = getMaxValue(values);
  const topBar = increaseByPercent(maxValue, maxValueAddPercent);
  const unit = chartHeight / topBar;

  const bars: Bar<EventSummary>[] = curation.map((item) => {
    return {
      height: item.totalEvents * unit,
      key: getUid(),
      value: item.totalEvents,
      valueFormatted: item.totalEvents.toLocaleString(),
      payload: {
        dateFormatted: getFormattedMonthDay(new Date(item.date)),
        dateIso: item.date,
        events: item.events.map((e) => {
          return {
            count: e.count,
            countFormatted: e.count.toLocaleString(),
            eventTypeMeta: getEventTypeMeta(e.eventType),
          };
        }),
      },
    };
  });

  const ticLabels = generateYTics(0, topBar, 8).filter(
    (t) => t * unit < chartHeight
  );

  const heights = ticLabels.reduce<Accum>(
    (prev, next) => {
      const now = prev.currentVal;
      const absHeight = unit * next;
      const newVal = absHeight - now;

      prev.currentVal = absHeight;
      prev.values.push(newVal);
      return prev;
    },
    {
      currentVal: 0,
      values: [],
    }
  );

  const tics: Tic[] = [];
  for (let i = 0; i < ticLabels.length; i++) {
    tics.push({
      height: heights.values[i],
      label: ticLabels[i].toLocaleString(),
      value: ticLabels[i],
      isInteger: Number.isInteger(ticLabels[i]),
    });
  }
  tics.reverse();

  return {
    bars,
    tics,
  };

  function getMaxValue(vals: number[]) {
    const maxFirst = vals.slice().sort((a, b) => {
      return a < b ? 1 : -1;
    });
    return maxFirst[0] ?? 0;
  }

  function increaseByPercent(value: number, percentage: number) {
    return value + (value * percentage) / 100;
  }
}
