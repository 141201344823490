/* eslint-disable arrow-body-style */
import React from 'react';
import { Drawer } from '@abyss/web/ui/Drawer';
import {
  eventSearchResultsApi,
  useEventSearchResultsStore,
} from '@src/routes/events/search/mothership/eventSearchResults.state';
import { ResultsStatus } from '../../../types';
import { EventItemDetailsDrawerContents } from './EventItemDetailDrawerContents';

export const EventItemDetailsDrawer = () => {
  const status = useEventSearchResultsStore((state) => state.eventDetailStatus);
  const eventDetail = useEventSearchResultsStore((state) => state.eventDetail);

  const { error, results, searching } = ResultsStatus;
  const open = [searching, results, error].includes(status);

  return (
    <Drawer
      title={eventDetail?.meta.label ?? ''}
      isOpen={open}
      onClose={eventSearchResultsApi.closeEventDetail}
      css={{
        'abyss-modal-content-container': {
          width: 'auto',
          minWidth: '40%',
        },
      }}
    >
      <div
        className="eventSearchEventDetailsResults"
        data-cy="eventSearchEventDetailsResults"
        data-testid="eventSearchEventDetailsResults"
      >
        <EventItemDetailsDrawerContents />
      </div>
    </Drawer>
  );
};
