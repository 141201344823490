/* eslint-disable arrow-body-style */
import React from 'react';

import { Button } from '@abyss/web/ui/Button';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const SearchButton = () => {
  const enabled = useEventSearchFilterPanelStore(
    (state) => state.searchBtnEnabled
  );
  return (
    <Button
      data-testid="event-search-btn"
      data-cy="event-search-btn"
      id="eventSearchBtn"
      variant="solid"
      disabled={!enabled}
      onClick={eventSearchFilterPanelApi.submit}
    >
      Search
    </Button>
  );
};
