export type EventBufferSnapShot = { isStale: () => boolean };

export function eventSequenceManager() {
  let eventId = Number.MIN_VALUE;

  function snapshot(snapshotId: number): EventBufferSnapShot {
    return {
      isStale: () => {
        return snapshotId !== eventId;
      },
    };
  }

  function queueEvent(): EventBufferSnapShot {
    eventId += 1;
    return snapshot(eventId);
  }

  function getSnapshot() {
    return snapshot(eventId);
  }

  return {
    queueEvent,
    getSnapshot,
  };
}
