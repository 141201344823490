/* eslint-disable arrow-body-style */
import React from 'react';
import { useEventSearchResultsStore } from '../../../mothership/eventSearchResults.state';
import { EventCell } from './EventCell';

export const ResultsTableBody = () => {
  const rows = useEventSearchResultsStore((state) => {
    return state.tableRows;
  });

  return (
    <tbody data-cy="resultsTableBody" data-testid="resultsTableBody">
      {rows.map(({ rowKey, cellValues, eventItem }) => {
        const cell0Key = cellValues[0].key;
        const cells = cellValues.slice(1);
        return (
          <tr key={rowKey}>
            <td key={cell0Key}>
              <EventCell eventItem={eventItem} />
            </td>
            {cells.map(({ key, value }) => {
              return <td key={key}>{value}</td>;
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
