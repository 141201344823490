/* eslint-disable @typescript-eslint/no-use-before-define */
import { dayjs } from '@abyss/web/tools/dayjs';
import { Portal } from '@src/routes/events/search/types';

export function formatCurrency(value: number | string): string {
  if (!isNumber(value)) return '';

  const fixed = (
    Math.round((Number(value) + Number.EPSILON) * 100) / 100
  ).toFixed(2);
  const [whole, decimal] = fixed.split('.');

  return `${Number(whole).toLocaleString()}.${decimal}`;
}

function isNumber(value: string | number | undefined | null) {
  const strValue = String(value ?? '').trim();
  const numValue = +strValue;
  const isNum = strValue !== '' && !Number.isNaN(numValue);
  return isNum;
}

export function getFormattedMonth(date: Date) {
  return dayjs(date).format('MMMM YYYY');
}

export function getFormattedMonthDay(date: Date) {
  return dayjs(date).format('MMMM DD');
}

export function getFormattedTimeDate(isoDate: string) {
  return dayjs(isoDate).format('hh:mm a, MMM DD');
}

export function formatPortal(portal: string) {
  const value = portal.trim().toUpperCase();
  if (value === Portal.bank) {
    return 'Optum Bank';
  }
  if (portal === Portal.pay) {
    return 'Optum Pay';
  }
  return portal;
}
