/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from 'react';
import { config } from '@abyss/api/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { useDataGrid } from '@abyss/web/hooks/useDataGrid';
import { DataGrid } from '@abyss/web/ui/DataGrid';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { DateInput } from '@abyss/web/ui/DateInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { ConfirmationModal } from '@src/common/Modal';
import { Card } from '@src/abyss/web/ui/Card';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useFindProvider } from '@src/hooks/providers/ffc/useFindProvider';
import { useUpdateProvider } from '@src/hooks/providers/ffc/useUpdate/useUpdateProvider';
import { Breadcrumbs } from '@src/abyss/web/ui/Breadcrumbs';
import { VisuallyHidden } from '@abyss/web/ui/VisuallyHidden';
import { v4 as uuidv4 } from 'uuid';
import { constraints } from '@src/routes/provider/common/constraints';
import { getReasonPhrase } from 'http-status-codes';
import { useHasRole } from '@src/hooks/useHasRole';
import { Alert } from '@abyss/web/ui/Alert';
import { Main } from '@src/app/Main/Main';
import { processor } from '../../common/process';
import { utils, IProviderHeading } from '../../common/dataGridUtils';
import { constants } from '../../common/constants';
import { publishNessEvent } from '../../../../common/logging/publishNessEvent';

const IS_LOCAL = ['local', 'dev'].includes(config('APP_ENV'));

const ColumnElement = constants.ColumnHeaderWidth;
const Header = constants.HeaderElement;

export const FFCProviderUpdatePage = ({
  tiggerUpdatedProvider,
  selectOptionsProps,
}) => {
  const [gridUpdated, setGridUpdated] = useState(uuidv4());

  const { navigate, getRouteParams } = useRouter();
  const params = getRouteParams();

  // the new id eventually returned from DB when record persisted
  const [fraudCaseId] = useState(() => {
    return params?.id || 'Unknown';
  });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [orgName, setOrgName] = useState('');
  const [middleInitial, setMiddleInitial] = useState('');
  const [tipSource, setTipSource] = useState('');
  const [notes, setNotes] = useState('');
  const [providerTypeCode, setProviderTypeCode] = useState('');
  const [presentedDate, setPresentedDate] = useState('');
  const [requestedDecisionCode, setRequestedDecisionCode] = useState('');
  const [dollarBills, setDollarBills] = useState('');

  const [cancelMsg, setCancelMsg] = useState('');
  const [cancelTitle, setCancelTitle] = useState('Cancel');
  const [modalOpen, setModalOpen] = useState(false);
  const [, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState('Loading details...');
  const [pageDirty, setPageDirty] = useState(false);

  // after a create op returns successfully we'll hold onto the newly created object for any subsequent updates
  const [fraudCase, setFraudCase] = useState({});

  const [, setCanReadFfc] = useState(false);
  const [, setCanWriteFfc] = useState(false);
  const [readonly, setReadoly] = useState(false);
  const [, getUsersRoles] = useHasRole({
    onCompleted: (response) => {
      const roles = Object.freeze(response?.data?.hasRole);
      setCanReadFfc(roles['read-ffc']);
      setCanWriteFfc(roles['write-ffc']);
      // eslint-disable-next-line eqeqeq
      setReadoly(roles['write-ffc'] == false);

      if (!(roles['read-ffc'] || roles['write-ffc'])) navigate('/');
    },
    onError: () => {
      setCanReadFfc(false);
      setCanWriteFfc(false);
      navigate('/');
    },
  });
  useState(() => {
    return getUsersRoles({
      variables: {
        roles: Object.freeze(['read-ffc', 'write-ffc']),
      },
    });
  });

  /**
   * Fire a 'Updating provider' event to NESS logs
   */
  const fireUpdateNessEvent = () => {
    /** publish an event to NESS */
    publishNessEvent({
      level: 'INFO',
      path: `providers/edit/${fraudCaseId}`,
      message: `Updating FFC Provider with id:${fraudCaseId}`,
      tags: ['FFC', 'PROVIDER', 'UPDATE'],
      query: `${fraudCaseId}`,
      op: 'POST',
      additionalFields: {
        'user-invoked': true,
        isUIAction: true,
        event: 'UPDATE-FFC-PROVIDER',
      },
    });
  };

  /**
   * Used to force update so useEffect() will kick in.
   */
  const onGridChange = (p1) => {
    setGridUpdated(uuidv4());
    return p1;
  };

  const dataGridPropsColumns = useMemo(() => {
    return [
      {
        title: 'Id',
        type: 'number',
        minWidth: 120,
        disabled: true,
      },
      {
        title: `Id Type ${config('REQUIRED_FIELD_INDICATOR')}`,
        placeholder: 'ID Type',
        type: 'select',
        options: selectOptionsProps.providerIdentifierTypes, // constants.ID_TYPES,
        // minWidth: 120,
        maxListHeight: 200,
        validator: onGridChange,
      },
      {
        title: `Id Value ${config('REQUIRED_FIELD_INDICATOR')}`,
        type: 'number',
        minWidth: 120,
        validator: onGridChange,
        cellClassName: ({ rowData }) => {
          if (rowData?.length > 150) {
            return 'custom-red-text';
          }
        },
      },
      {
        title: 'Name',
        placeholder: 'Enter Text',
        defaultValue: '',
        minWidth: 120,
        validator: onGridChange,
        cellClassName: ({ rowData }) => {
          if (rowData?.length > 150) {
            return 'custom-red-text';
          }
        },
      },
      {
        title: 'Address 1',
        placeholder: 'Enter Text',
        defaultValue: '',
        validator: onGridChange,
        cellClassName: ({ rowData }) => {
          if (rowData?.length > 150) {
            return 'custom-red-text';
          }
        },
      },
      {
        title: 'Address 2',
        placeholder: 'Enter Text',
        defaultValue: '',
        validator: onGridChange,
        cellClassName: ({ rowData }) => {
          if (rowData?.length > 150) {
            return 'custom-red-text';
          }
        },
      },
      // { // apparently we'll only use USA until we don't, right :-)
      // 	title: 'Country',
      // 	type: 'select',
      // 	options: MOCK_COUNTRIES_DATA,
      // 	placeholder: 'Pick an option',
      // 	minWidth: 120,
      // },
      {
        title: `State ${config('REQUIRED_FIELD_INDICATOR')}`,
        type: 'select',
        options: selectOptionsProps.states,
        minWidth: 120,
        validator: onGridChange,
      },
      {
        title: 'City',
        placeholder: 'Enter Text',
        defaultValue: '',
        validator: onGridChange,
        cellClassName: ({ rowData }) => {
          if (rowData?.length > 150) {
            return 'custom-red-text';
          }
        },
      },
      {
        title: 'Postal Code',
        placeholder: 'add number here',
        defaultValue: '',
        minWidth: 120,
        validator: onGridChange,
        cellClassName: ({ rowData }) => {
          if (rowData?.length > 150) {
            return 'custom-red-text';
          }
        },
      },
      {
        title: `Appr Decision ${config('REQUIRED_FIELD_INDICATOR')}`,
        type: 'select',
        options: selectOptionsProps.decisionCodes,
        minWidth: 200,
        validator: (p1) => {
          setGridUpdated(uuidv4());
          const arr = Object.values(p1);
          const approverDecision = arr[constants.APPROVER_DECISION_INDEX];
          const procedureCodeKey =
            Object.keys(p1)[constants.PROCEDURE_CODE_KEY_INDEX];
          if (approverDecision === constants.HDCS_CODE) {
            return {
              ...p1,
              [procedureCodeKey]: constants.ENTER_PROC_CODE_MSG,
            };
          }
          return {
            ...p1,
            [procedureCodeKey]: constants.NON_HDCS_CELL_PROC_CODE_ERROR,
          };
        },
      },
      {
        title: 'Procedure-Codes',
        type: 'text',
        defaultValue: '',
        minWidth: 150,
        maxWidth: 260,
        validator: (p1) => {
          setGridUpdated(uuidv4());
          const arr = Object.values(p1);
          const approverDecision = arr[constants.APPROVER_DECISION_INDEX];
          if (approverDecision === constants.HDCS_CODE) {
            // allow entry of codes
            return p1;
          }
          // do not allow entry of codes
          const procedureCodeKey =
            Object.keys(p1)[constants.PROCEDURE_CODE_KEY_INDEX];
          return {
            ...p1,
            [procedureCodeKey]: constants.NON_HDCS_CELL_PROC_CODE_ERROR,
          };
        },
        cellClassName: ({ rowData }) => {
          if (rowData?.length > constraints.procedureCodes.maxLen) {
            return 'custom-red-text';
          }
          if (
            constraints.procedureCodes.exclude.some((value) => {
              return rowData?.includes(value);
            })
          ) {
            return 'custom-red-text';
          }
          if (rowData === constants.NON_HDCS_CELL_PROC_CODE_ERROR) {
            return 'custom-grey-text';
          }
          return 'custom-grey-text';
        },
      },
      {
        title: `Appr Decision Date ${config('REQUIRED_FIELD_INDICATOR')}`,
        type: 'date',
        // defaultValue: `${dayjs().format('MM/DD/YYYY')}`,
        minWidth: 180,
        validator: onGridChange,
      },
      {
        title: `Reason For Approved Decision ${config(
          'REQUIRED_FIELD_INDICATOR'
        )}`,
        type: 'select',
        options: selectOptionsProps.decisionReasons,
        minWidth: 250,
        validator: onGridChange,
      },
      {
        title: 'Removal Date',
        type: 'date',
        // defaultValue: `${dayjs().format('MM/DD/YYYY')}`,
        minWidth: 120,
        validator: onGridChange,
      },
      {
        title: 'Removal Reason',
        placeholder: 'Enter Text',
        validator: onGridChange,
        cellClassName: ({ rowData }) => {
          if (rowData?.length > 150) {
            return 'custom-red-text';
          }
        },
      },
    ];
  }, []);

  const dataGridProps = useDataGrid({
    initialData: constants.INIT_EMPTY_IDENTITY_GRID_DATA,
    initialColumns: dataGridPropsColumns,
    hideGutterColumn: true,
    disableInsertColumn: true,
    rowResize: false,
    columnResize: true,
    columnMove: false,
    disableDeleteColumn: false,
    rowMove: false,
    columnSort: true,
    columnFilter: false,
    startRows: 4,
    maxHeight: '100%',
    onEditLeave: () => {
      setGridUpdated(uuidv4());
    },
    onSelectionChange: () => {
      setGridUpdated(uuidv4());
    },
    onRowCreate: (columns) => {
      // eslint-disable-next-line array-callback-return
      const defaultData = columns.reduce((dataObj, { id }) => {
        if (id !== 'hidden-gutter')
          return {
            ...dataObj,
            [id]: '',
          };
      }, {});
      return defaultData;
    },
    css: {
      'custom-red-text': {
        '.abyss-text-input, .abyss-data-grid-cell-display-text': {
          color: '$error1',
          fontWeight: '$bold',
        },
      },
      'custom-grey-text': {
        '.abyss-text-input, .abyss-data-grid-cell-display-text': {
          color: '$gray6',
          backgroundColor: '$gray2',
          fontWeight: '$bold',
        },
      },
      'custom-id-grey-text': {
        '.abyss-text-input, .abyss-data-grid-cell-display-text': {
          color: '$gray2',
          backgroundColor: '$gray2',
          fontWeight: '$bold',
        },
      },
    },
  });

  const form = useForm({
    defaultValues: {
      requestedDecisionCode: constants.HD_CODE,
      // presentedDate: `${dayjs().format('MM/DD/YYYY')}`,
      dollarBills: '0.0',
    },
  });

  const redirectPage = (target = '/providers/create') => {
    form.reset();
    dataGridProps.updateData([]);
    setIsLoading(false);
    navigate(target);
  };

  const displayError = (error) => {
    const title =
      getReasonPhrase(error.status) ||
      error?.message ||
      error?.data?.title ||
      error?.data?.message ||
      'Unexpected Error!';
    const violations = error?.data?.violations
      ?.map((e) => {
        return `${e?.path}:${e?.message}`;
      })
      ?.join('\n');
    const message = violations || error?.data?.detail;

    utils.popupMsg(title, message);
  };

  const [, findProvider] = useFindProvider({
    onCompleted: (response) => {
      const status = response?.data?.findProvider.status;

      if (status === 200) {
        setLoadingMsg(`Successfully found provider with id: ${fraudCaseId}`);
        const provider = response.data.findProvider?.response;
        processor.rehydrateHeader(
          provider,
          form,
          selectOptionsProps.decisionCodes,
          selectOptionsProps.providerTypes
        );
        setOrgName(provider?.orgName);
        setFirstName(provider?.firstName);
        setLastName(provider?.lastName);
        processor.rehydrateGrids(provider, dataGridProps);
        setFraudCase(provider);
      } else if (status === 404) {
        // if we can't find the case then return to main search page
        redirectPage('/providers/manage');
        utils.popupMsg(
          'No Record Found',
          `Can't find a Fraud Case matching id '${fraudCaseId}'.\nYou can perform a more detailed search from here.`
        );
      } else {
        displayError(response?.data?.findProvider);
      }
      setIsLoading(false);
    },
    onError: (err) => {
      console.error(err);
      const title = err?.response?.statusText || 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
      setIsLoading(false);
    },
  });

  const [, updateProvider] = useUpdateProvider({
    onCompleted: (response) => {
      const status = response?.data?.updateProvider.status;

      if (status === 200) {
        const updatedProvider = response.data.updateProvider.response;
        setFraudCase(updatedProvider);
        processor.rehydrateHeader(
          updatedProvider,
          form,
          selectOptionsProps.decisionCodes,
          selectOptionsProps.providerTypes
        );
        setOrgName(updatedProvider?.orgName);
        setFirstName(updatedProvider?.firstName);
        setLastName(updatedProvider?.lastName);
        processor.rehydrateGrids(updatedProvider, dataGridProps);

        tiggerUpdatedProvider(updatedProvider);

        utils.successPopupMsg(
          'Case Updated',
          `Successfully updated case with ID: ${updatedProvider.id}`
        );
      } else {
        displayError(response?.data?.updateProvider);
      }
      setIsLoading(false);
    },
    onError: (err) => {
      setIsLoading(false);
      const title = err?.response?.statusText || 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
    },
  });

  const [, updateProviderWithNewAction] = useUpdateProvider({
    onCompleted: (response) => {
      const status = response?.data?.updateProvider.status;

      if (status === 200) {
        tiggerUpdatedProvider(response.data.updateProvider.response);
        utils.successPopupMsg(
          'Case Updated',
          `Successfully updated case with ID: ${response.data.updateProvider.response.id}`
        );

        redirectPage();
      } else {
        displayError(response?.data?.updateProvider);
      }
      setIsLoading(false);
    },
    onError: (err) => {
      setIsLoading(false);
      const title = err?.response?.statusText || 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
    },
  });

  const [,] = useState(() => {
    const variables = {
      id: fraudCaseId,
    };
    setIsLoading(true);
    findProvider({ variables });
  });

  useEffect(() => {
    // if user has refreshed page then we also need to refresh the header and grid
    processor.rehydrateHeader(
      fraudCase,
      form,
      selectOptionsProps.decisionCodes,
      selectOptionsProps.providerTypes
    );
    processor.rehydrateGrids(fraudCase, dataGridProps);
  }, [fraudCase, selectOptionsProps]);

  useEffect(() => {
    form.trigger();

    setPageDirty(processor.dirty(form, dataGridProps.getData(), fraudCase));
  }, [
    fraudCase,
    isLoading,
    loadingMsg,
    cancelMsg,
    cancelTitle,
    pageDirty,
    gridUpdated,
    firstName,
    lastName,
    orgName,
    middleInitial,
    tipSource,
    notes,
    providerTypeCode,
    presentedDate,
    requestedDecisionCode,
    dollarBills,
    form.formState.isValid,
  ]);

  const doSave = (request) => {
    const variables = {
      id: request.id,
      body: `${JSON.stringify(request)}`,
    };
    updateProvider({ variables });
  };
  const doSaveWithNewAction = (request) => {
    const variables = {
      id: request.id,
      body: `${JSON.stringify(request)}`,
    };
    updateProviderWithNewAction({ variables });
  };

  const onSubmit = (data) => {
    const [valid, disallowedEntities] = processor.validateUpdate(
      dataGridProps.getData()
    );

    if (valid) {
      setIsLoading(true);

      // whatever is on the screen is valid so let's create request to send to backend
      const updateRequest: IProviderHeading = processor.buildUpdateRequest(
        parseInt(fraudCaseId, 10),
        data,
        disallowedEntities
      );

      const dirty = processor.dirty(form, dataGridProps.getData(), fraudCase);

      if (!dirty) {
        utils.popupMsg(
          'Update Provider',
          'There were no changes detected so nothing to update'
        );
        setIsLoading(false);
        return;
      }
      // utils.loadingPopupMsg('Processing', 'Please wait while we process your changes');
      setLoadingMsg('Please wait while we process your changes');
      doSave(updateRequest);
    }
  };

  const onSubmitWithNewAction = (data) => {
    const [valid, disallowedEntities] = processor.validateUpdate(
      dataGridProps.getData()
    );

    if (valid) {
      setIsLoading(true);
      // utils.loadingPopupMsg('Processing', 'Please wait while we process your changes');
      setLoadingMsg('Please wait while we process your changes');

      // whatever is on the screen is valid so let's create request to send to backend
      const updateRequest: IProviderHeading = processor.buildUpdateRequest(
        parseInt(fraudCaseId, 10),
        data,
        disallowedEntities
      );
      const dirty = processor.dirty(form, dataGridProps.getData(), fraudCase);
      if (dirty) {
        doSaveWithNewAction(updateRequest);
      } else {
        redirectPage();
      }
    }
    // setIsLoading(false);
  };

  // Trigger one input to validate
  const triggerSaveState = () => {
    const [valid, title, message] = utils.isHeaderInfoValid(form.getValues());
    if (valid) {
      fireUpdateNessEvent();
      onSubmit(form.getValues());
    } else {
      utils.popupMsg(title, message);
    }
  };
  const triggerSaveAndNewState = () => {
    const [valid, title, message] = utils.isHeaderInfoValid(form.getValues());
    if (valid) {
      fireUpdateNessEvent();
      onSubmitWithNewAction(form.getValues());
    } else {
      utils.popupMsg(title, message);
    }
  };
  const triggerCancel = () => {
    if (processor.dirty(form, dataGridProps.getData(), fraudCase)) {
      setCancelTitle('Cancel Changes');
      setCancelMsg(
        'You have unsaved changes, are you sure you want to cancel?'
      );
    } else {
      setCancelTitle('Cancel');
      setCancelMsg('Are you sure you want to cancel?');
    }
    setModalOpen(true);
  };

  return (
    <Main id="providerUpdatePage">
      <div
        style={{
          padding: '15px',
          margin: '10px',
          maxWidth: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {/* We look at how Volcan use this but this is only temp for now */}
        <Breadcrumbs
          // divider={'...'}
          isDarkMode
          leadingIcon={<IconMaterial icon="home" size="24px" />}
          items={[
            { title: 'Home', href: '/' },
            { title: 'Manage FFC Cases', href: '/providers/manage' },
            { title: 'Edit Provider', href: '/providers/edit/{:id}' },
          ]}
        />
      </div>

      <Alert
        // title="Read Only Mode"
        variant="info"
        isVisible={readonly}
        onClose={() => {
          // do nothing
        }}
      >
        Unfortunately you do not have permission to make any changes to this
        provider
      </Alert>

      <div
        style={
          readonly
            ? constants.READ_ONLY_STYLE_UPDATE_FFC_PROVIDER
            : constants.READ_WRITE_STYLE_UPDATE_FFC_PROVIDER
        }
      >
        <Divider color="$primary1" />
        <FormProvider
          state={form}
          onSubmit={onSubmit}
          descriptorsDisplay="column"
        >
          {/* The name entry widgets */}
          <ColumnElement>
            <Header>
              <Flex>
                <TextInput
                  label="First Name"
                  errorMessage="First Name Required"
                  model="firstName"
                  validators={{
                    required: orgName?.length === 0,
                    maxLength: {
                      value: 50,
                      message: 'Max Length 50',
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_-]{1,50}$/,
                      message: 'No special characters allowed',
                    },
                  }}
                  onChange={setFirstName}
                  onKeyDown={setFirstName}
                  subText=""
                />
              </Flex>
            </Header>

            <Header>
              <TextInput
                label="Last Name"
                errorMessage="Last Name Required"
                model="lastName"
                validators={{
                  required: orgName?.length === 0,
                  maxLength: {
                    value: 50,
                    message: 'Max Length 50',
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9_-]{1,50}$/,
                    message: 'No special characters allowed',
                  },
                }}
                onKeyDown={setLastName}
                onChange={setLastName}
                subText=""
              />
            </Header>

            <Header>
              <TextInput
                label="Middle Initial"
                placeholder=""
                model="middleInitial"
                onKeyDown={setMiddleInitial}
                onChange={setMiddleInitial}
                validators={{
                  required: false,
                  maxLength: {
                    value: 1,
                    message: 'Max Length 1',
                  },
                  pattern: {
                    value: /^[a-zA-Z]{1}$/,
                    message: 'No special characters allowed',
                  },
                }}
                subText=""
              />
            </Header>
          </ColumnElement>

          <ColumnElement>
            <Header>
              <TextInput
                label="Org Name"
                errorMessage="Org Name Required"
                model="orgName"
                onKeyDown={setOrgName}
                onChange={setOrgName}
                validators={{
                  required: lastName?.length === 0 || firstName?.length === 0,
                  maxLength: {
                    value: 150,
                    message: 'Max Length 150',
                  },
                }}
              />
            </Header>

            <Header data-cy="fpu-input-provider-type">
              <SelectInput
                label="Provider Type"
                placeholder="Select Type"
                model="providerTypeCode"
                isSearchable
                onChange={setProviderTypeCode}
                options={selectOptionsProps.providerTypes}
                validators={{ required: true }}
              />
            </Header>

            <Header>
              <TextInput
                label="Source of Tip"
                errorMessage="Source of Tip Required"
                model="tipSource"
                onChange={setTipSource}
                validators={{
                  required: true,
                  maxLength: {
                    value: 150,
                    message: 'Max Length 150',
                  },
                }}
              />
            </Header>
          </ColumnElement>

          <ColumnElement>
            <Header data-cy="fpu-input-requested-decision-code">
              <SelectInput
                label="Requested Decision"
                model="requestedDecisionCode"
                isSearchable
                onChange={setRequestedDecisionCode}
                options={selectOptionsProps.decisionCodes}
                validators={{
                  required: false,
                }}
              />
            </Header>

            <Header>
              <TextInput
                label="Dollar's Bill"
                model="dollarBills"
                onChange={setDollarBills}
                type="text"
                mask="numeric"
                returnUnmaskedValue
                maskConfig={{
                  thousandSeparator: ',',
                  fixedDecimalScale: true,
                  decimalScale: 2,
                  prefix: '$',
                }}
                // isClearable
                validators={{
                  required: false,
                  validate: {
                    notLessThanZero: (v) => {
                      return v >= 0 || 'Negative values not allowed';
                    },
                  },
                }}
              />
            </Header>

            <Header>
              <DateInput
                label="Presented Date"
                model="presentedDate"
                value={presentedDate}
                onChange={setPresentedDate}
                onKeyDown={setPresentedDate}
                validators={{ required: true }}
              />
            </Header>
          </ColumnElement>

          {/* notes section */}
          <div
            style={{
              width: '30%',
              display: 'inline-block',
              margin: '5px',
              verticalAlign: 'top',
            }}
          >
            <TextInputArea
              label="Notes"
              model="notes"
              onChange={setNotes}
              validators={{
                required: false,
                maxLength: {
                  value: 1_000,
                  message: 'Max Length 1000',
                },
              }}
              rows={7}
              subText={`Enter notes here: (${
                form?.getValues('notes')?.length
              }/1000) chars`}
            />
          </div>
        </FormProvider>

        <Divider color="$primary1" />

        {/* ID table */}
        <div
          style={{
            width: '100%',
            display: 'inline-block',
            margin: '5px',
            paddingTop: '20px',
            verticalAlign: 'centre',
          }}
        >
          <DataGrid gridTitle="Disallowed Entity" gridState={dataGridProps} />
        </div>
      </div>

      <Divider color="$primary1" />

      {/* save delete etc. */}
      <div
        style={{
          width: '85%',
          display: 'inline-block',
          margin: '5px',
          paddingTop: '40px',
          verticalAlign: 'top',
        }}
      >
        <Flex>
          <div style={{ padding: '5px', margin: '1px' }}>
            <Button
              variant="outline"
              before={<IconMaterial icon="save" color="$primary1" />}
              type="submit"
              isDisabled={!pageDirty}
              onClick={triggerSaveState}
              data-cy="fpu-btn-save"
            >
              Save
            </Button>
          </div>
          <div tabIndex={-1} style={{ padding: '5px', margin: '1px' }}>
            <Button
              variant="outline"
              before={<IconMaterial icon="save" color="$primary1" />}
              type="submit"
              isDisabled={!pageDirty}
              onClick={triggerSaveAndNewState}
              data-cy="fpu-btn-save-and-new"
            >
              Save and New
            </Button>
          </div>
          <VisuallyHidden id="reason-disabled">
            The Save buttons are disabled because no changes have been detected.
          </VisuallyHidden>
          <div style={{ padding: '5px', margin: '1px' }}>
            <Button
              variant="outline"
              before={<IconMaterial icon="cancel" color="$primary1" />}
              onClick={triggerCancel}
              data-cy="fpu-btn-cancel"
            >
              Cancel
            </Button>
          </div>
        </Flex>
      </div>

      {/* </FormProvider> */}
      <LoadingOverlay
        loadingTitle="Processing..."
        loadingMessage={loadingMsg}
        ariaLoadingLabel="This is the ariaLoadingLabel prop."
        isLoading={isLoading}
      >
        <Card
          css={{
            'abyss-card-root': {
              height: 250,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '$gray4',
            },
          }}
        />
      </LoadingOverlay>

      <ConfirmationModal
        title={cancelTitle}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setUrl('#');
        }}
        nextButton="Yes"
        backButton="No"
        message={cancelMsg}
        href="/providers/manage"
      />

      {/* <Divider color="$primary1" /> */}

      <div
        style={{
          paddingTop: '40px',
          verticalAlign: 'bottom',
          display: IS_LOCAL ? 'inline-block' : 'none',
        }}
      />
    </Main>
  );
};
