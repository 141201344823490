import { BarSelectStatus } from '@src/common/charts/types';
import { NullableEventSummary } from '../../types';

export function getBarSelectedStatus(
  eventItemSummary: NullableEventSummary,
  isoDate: string
): BarSelectStatus {
  if (!eventItemSummary) {
    return BarSelectStatus.noBarSelected;
  }
  if (isoDate === eventItemSummary.dateIso) {
    return BarSelectStatus.barSelected;
  }

  return BarSelectStatus.barDeselected;
}
