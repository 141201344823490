/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { ajax } from '@src/common/ajax';
import { ApiUrl } from '@src/common/ajax/apiUrl';
import { AjaxResponse } from '@src/common/ajax/types';
import { defaultEventItemDetail } from '../EventItemDetail';
import { EventItemDetail } from '../types';

export async function fetchEventDetail(
  id: string
): Promise<AjaxResponse<EventItemDetail>> {
  const fallbackResponse: EventItemDetail = {
    ...defaultEventItemDetail,
  };

  const { payload, ...rest } = await ajax(
    fallbackResponse,
    ApiUrl.eventDetail(id),
    {
      method: 'get',
    }
  );

  return {
    payload: sanitize(payload),
    ...rest,
  };
}

function sanitize(data: EventItemDetail): EventItemDetail {
  const withAllProps = Object.assign(defaultEventItemDetail, data);
  const res = Object.entries(withAllProps).reduce<EventItemDetail>(
    (prev, next) => {
      prev[next[0]] = next[1] ?? getDefaultVal(next[0]);
      prev[next[0]] = removeNulls(next[0], prev[next[0]]);
      return prev;
    },
    { ...defaultEventItemDetail }
  );
  return res;

  function getDefaultVal(key: string) {
    return ['deviceIds', 'urls', 'userOss'].includes(key) ? [] : '';
  }

  function removeNulls(key: string, value: string[] | string) {
    return ['deviceIds', 'urls', 'userOss'].includes(key) &&
      Array.isArray(value)
      ? value.filter((item) => !!item)
      : value;
  }
}
