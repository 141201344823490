import React, { FC } from 'react';
import { Tic } from '../types';

export interface YAxisProps {
  tics: Tic[];
  heading: string;
  hideNonWholeNumbers: boolean;
}

export const YAxis: FC<YAxisProps> = ({
  tics,
  heading,
  hideNonWholeNumbers,
}) => {
  const clazz = hideNonWholeNumbers ? 'yAxis hideNonIntegerNumbers' : 'yAxis';

  return (
    <React.Fragment>
      <div className="yAxisHeader">
        <div className="yAxisHeaderContent">{heading}</div>
      </div>
      <div className={clazz} data-cy="dfedsChartYAxis">
        {tics.map(({ label, height, isInteger }) => {
          const lineClass = `dfedsGridLine${
            isInteger ? '' : ' nonIntegerNumber'
          }`;
          return (
            <div
              key={label}
              className={lineClass}
              style={{
                height: `${height}px`,
              }}
            >
              <div className="dfedsGridLineLabel">{label}</div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
