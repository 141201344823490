/* eslint-disable arrow-body-style */
import React from 'react';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { SubHeaderButton } from '@src/common/SubHeaderButton';
import {
  eventSearchResultsApi,
  useEventSearchResultsStore,
} from '../../../mothership/eventSearchResults.state';

export const ResultsTableControls = () => {
  const totalRowCount = useEventSearchResultsStore(
    (state) => state.totalRowCount
  );
  const visibleRowCount = useEventSearchResultsStore(
    (state) => state.eventItems
  ).length;

  return (
    <div className="eventSearchTableControls">
      <div
        className="showingCounts"
        data-cy="eventSearchTableShowingCounts"
        data-testid="eventSearchTableShowingCounts"
      >
        Showing 1 – {visibleRowCount} of {totalRowCount} results
      </div>
      <SubHeaderButton
        aria-haspopup="dialog"
        variant="ghost"
        before={<IconSymbol icon="view_column" variant="outlined" />}
        onClick={eventSearchResultsApi.showTableColSelector}
      >
        Table Settings
      </SubHeaderButton>
    </div>
  );
};
